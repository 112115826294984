






















import Vue from 'vue';
import { IStyleContent, ITherapyContent, StyleTypeEnum, TherapyTypeEnum } from '@/scripts/store/modules/therapy/types';
import ElementStyledContent from '@/views/products/components/element-styled-content.vue';
import ElementAccordion from '@/views/products/components/elements/element-accordion.vue';
import ElementAssessment from '@/views/products/components/elements/element-assessment.vue';
import ElementCarousel from '@/views/products/components/elements/element-carousel.vue';
import ElementCuScheduler from '@/views/products/components/elements/element-cu-scheduler.vue';
import ElementDecision from '@/views/products/components/elements/element-decision.vue';
import ElementGoal from '@/views/products/components/elements/element-goal.vue';
import ElementJournal from '@/views/products/components/elements/element-journal.vue';
import ElementMcq from '@/views/products/components/elements/element-mcq.vue';
import ElementMsq from '@/views/products/components/elements/element-msq.vue';
import ElementMood from '@/views/products/components/elements/element-mood.vue';
import ElementNextStep from '@/views/products/components/elements/element-next-step.vue';
import ElementPoll from '@/views/products/components/elements/element-poll.vue';
import ElementPopup from '@/views/products/components/elements/element-popup.vue';
import ElementSleepTrack from '@/views/products/components/elements/element-sleep-track.vue';
import ElementSleepWindow from '@/views/products/components/elements/element-sleep-window.vue';
import ElementSlider from '@/views/products/components/elements/element-slider.vue';
import ElementSubmenu from '@/views/products/components/elements/element-submenu.vue';
import ElementThoughts from '@/views/products/components/elements/element-thoughts.vue';
import ElementVideo from '@/views/products/components/elements/element-video.vue';
import ElementWorkbook from '@/views/products/components/elements/element-workbook.vue';
import ElementFeedback, { IFeedback } from '@/views/products/components/element-feedback.vue';
import ElementNote from '@/views/products/components/elements/element-note.vue';

interface ICompletedPayload {
  valid: boolean,
  autoComplete: boolean,
  feedback?: Array<IStyleContent[]>,
  oldSelection: boolean,
  therapyContent: ITherapyContent,
  pickup: boolean
}

export default Vue.extend({
  name: 'element-content',
  components: {
    ElementStyledContent,
    ElementAccordion,
    ElementAssessment,
    ElementCarousel,
    ElementCuScheduler,
    ElementDecision,
    ElementGoal,
    ElementJournal,
    ElementMcq,
    ElementMsq,
    ElementMood,
    ElementNextStep,
    ElementPoll,
    ElementPopup,
    ElementSleepTrack,
    ElementSleepWindow,
    ElementSlider,
    ElementSubmenu,
    ElementThoughts,
    ElementVideo,
    ElementWorkbook,
    ElementFeedback,
    ElementNote,
  },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContent,
      required: true,
    },
  },
  data() {
    return {
      feedback: null as IFeedback[] | null,
      types: TherapyTypeEnum,
    };
  },
  computed: {
    debugEnabled() {
      return this.$store.getters.debugEnabled;
    },
    componentName(): string | undefined {
      if (this.therapyContent.type === TherapyTypeEnum.COMPOSITION || this.therapyContent.type === TherapyTypeEnum.HTML) return;
      return `element-${this.therapyContent.type.toLowerCase().replace('_', '-')}`;
    },
    isOnLastPosition(): boolean {
      if (!this.therapyContent.styleContent?.length || this.therapyContent.type === TherapyTypeEnum.ASSESSMENT) return false;
      const lastPart = this.therapyContent.styleContent[this.therapyContent.styleContent.length - 1];
      return lastPart.key === StyleTypeEnum.CONTENT || lastPart.key === StyleTypeEnum.PLACEHOLDER;
    },
  },
  methods: {
    onCompleteElement(payload: ICompletedPayload) {
      this.$log.debug('Completed therapy content', this.therapyContent);
      this.$log.debug('Payload', payload);
      if (!payload.valid) {
        // eslint-disable-next-line vue/no-mutating-props
        this.therapyContent.answered = false;
        return;
      }

      // eslint-disable-next-line vue/no-mutating-props
      this.therapyContent.answered = true;
      this.formatFeedback(payload);

      this.$emit('completeElement', this.therapyContent);

      if (payload.autoComplete) {
        this.$emit('autoComplete', { therapyContent: payload.therapyContent, pickup: payload.pickup });
      }
    },
    formatFeedback(payload: ICompletedPayload) {
      if (!payload.feedback || !payload.feedback.length) {
        this.feedback = null;
        return;
      }

      this.feedback = payload.feedback.filter(feedback => feedback.length && !(feedback.length === 1 && feedback[0].key === StyleTypeEnum.CONTENT))
        .map(feedback => {
          return {
            content: feedback,
            scrollOnInit: !payload.oldSelection,
          };
        });
    },
    updateBackground(bgStyle: CSSStyleDeclaration) {
      this.$emit('updateBackground', bgStyle);
    },
  },
});
