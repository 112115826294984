import { Module } from 'vuex';
import { RootState } from '@/scripts/store/types';
import { Tool, ToolState } from './types';

const intialState: ToolState = {
  tools: [],
};

export const defaultState: ToolState = {
  ...intialState,
};

export const tools: Module<ToolState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    tools(state, tools: Array<Tool>) {
      state.tools = tools;
    },
  },
  actions: {
    getTools({ commit, state, dispatch }) {
      const callback = (res: Array<Tool>) => {
        commit('tools', res);
        return res;
      };
      return dispatch('$apiRequest', [ '/tools', callback ], { root: true });
    },
  },
  getters: {
    tools(state): Array<Tool> {
      return state.tools;
    },
  },
};
