




























import Vue from 'vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import store from '@/scripts/store';
import { format, parse } from 'date-fns';

export const DATE_FORMAT = 'yyyy-MM-dd';

export default Vue.extend({
  name: 'sm-input-date-field',
  components: { PrimaryButton },
  props: {
    value: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    minDate: {
      type: String,
      required: false,
    },
    maxDate: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showDialog: false,
      internalValue: undefined as string | undefined,
      rules: {
        required: [ (v: string) => !!v || this.$t('common.errors.required') ],
      },
    };
  },
  computed: {
    currentLocale() {
      return store.getters.getCurrentLocale;
    },
    currentLanguage() {
      return store.getters.currentLanguage;
    },
    abbreviatedDateFormat() {
      return store.getters.getAbbreviatedDateFormat;
    },
    formattedDate: {
      get(): string | null {
        return this.internalValue ? format(parse(this.internalValue, DATE_FORMAT, new Date()), this.abbreviatedDateFormat, { locale: this.currentLocale }) : null;
      },
      set(newValue: string) {
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue === oldValue) return;
        this.internalValue = newValue;
      },
    },
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.internalValue = this.value;
    },
    save() {
      this.showDialog = false;
      this.$emit('input', this.internalValue);
    },
  },
});
