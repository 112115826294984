var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      ref: "graph",
      staticClass: "d-block",
      staticStyle: { "margin-left": "auto", "margin-right": "auto" },
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: _vm.showLabels ? "0 0 410.79 320.26" : "55 0 280 280",
        width: _vm.canvasWidth
      }
    },
    [
      _vm.ENERGY
        ? _c("g", { staticClass: "ENERGY" }, [
            _vm.showLabels
              ? _c(
                  "text",
                  {
                    staticClass: "label",
                    attrs: {
                      fill: _vm.ENERGY.color,
                      transform: "translate(278.95 15.42)"
                    },
                    on: {
                      click: function($event) {
                        return _vm.onSelect("ENERGY")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.ENERGY.assessmentLabel))]
                )
              : _vm._e(),
            _vm.showLabels
              ? _c(
                  "text",
                  {
                    staticClass: "value",
                    attrs: { transform: "translate(278.31 37.52)" }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.mode === _vm.modes.completed ? _vm.ENERGY.label : ""
                      )
                    )
                  ]
                )
              : _vm._e(),
            _c("path", {
              staticClass: "very-low",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.ENERGY.value >= 0
                    ? _vm.ENERGY.color
                    : "#ededed",
                d:
                  "M286.86,242.27a27.74,27.74,0,0,0-12.61-9.21,8.41,8.41,0,0,1-5.47-7.94h0a8.49,8.49,0,0,1,11.35-8,44.69,44.69,0,0,1,20.15,14.72,8.5,8.5,0,0,1-4.14,13.24h0A8.42,8.42,0,0,1,286.86,242.27Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("ENERGY")
                }
              }
            }),
            _c("path", {
              staticClass: "low",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.ENERGY.value >= 1
                    ? _vm.ENERGY.color
                    : "#ededed",
                d:
                  "M310.72,233.13a52.66,52.66,0,0,0-35.11-25.6,8.54,8.54,0,0,1-6.83-8.33h0a8.5,8.5,0,0,1,10.2-8.33,69.58,69.58,0,0,1,46.56,34,8.5,8.5,0,0,1-4.78,12.26h0A8.53,8.53,0,0,1,310.72,233.13Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("ENERGY")
                }
              }
            }),
            _c("path", {
              staticClass: "moderate",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.ENERGY.value >= 2
                    ? _vm.ENERGY.color
                    : "#ededed",
                d:
                  "M334.66,225A77.57,77.57,0,0,0,276,182.22a8.46,8.46,0,0,1-7.2-8.4h0a8.52,8.52,0,0,1,9.8-8.41,94.55,94.55,0,0,1,71.28,51.92,8.52,8.52,0,0,1-5,11.9h0A8.46,8.46,0,0,1,334.66,225Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("ENERGY")
                }
              }
            }),
            _c("path", {
              staticClass: "moderate",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.ENERGY.value >= 3
                    ? _vm.ENERGY.color
                    : "#ededed",
                d:
                  "M358.45,216.78a102.54,102.54,0,0,0-82-59.71,8.54,8.54,0,0,1-7.64-8.45h0a8.5,8.5,0,0,1,9.42-8.45A119.39,119.39,0,0,1,374,209.88a8.5,8.5,0,0,1-5.13,11.56h0A8.53,8.53,0,0,1,358.45,216.78Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("ENERGY")
                }
              }
            })
          ])
        : _vm._e(),
      _vm.PHQ9
        ? _c("g", { staticClass: "PHQ9" }, [
            _vm.showLabels
              ? _c(
                  "text",
                  {
                    staticClass: "label",
                    attrs: {
                      fill: _vm.PHQ9.color,
                      transform: "translate(175.55 288.2)"
                    },
                    on: {
                      click: function($event) {
                        return _vm.onSelect("PHQ9")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.PHQ9.assessmentLabel))]
                )
              : _vm._e(),
            _vm.showLabels
              ? _c(
                  "text",
                  {
                    staticClass: "value",
                    attrs: { transform: "translate(175.06 311.43)" }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.mode === _vm.modes.completed ? _vm.PHQ9.label : ""
                      )
                    )
                  ]
                )
              : _vm._e(),
            _c("path", {
              staticClass: "very-low",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.PHQ9.value >= 0
                    ? _vm.PHQ9.color
                    : "#ededed",
                d:
                  "M256.47,284.67a27.76,27.76,0,0,0,15.62,0,8.41,8.41,0,0,1,9.09,3.2h0a8.48,8.48,0,0,1-4.47,13.15,44.58,44.58,0,0,1-24.95-.07,8.49,8.49,0,0,1-4.44-13.14h0A8.41,8.41,0,0,1,256.47,284.67Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("PHQ9")
                }
              }
            }),
            _c("path", {
              staticClass: "low",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.PHQ9.value >= 1
                    ? _vm.PHQ9.color
                    : "#ededed",
                d:
                  "M242.55,306.09a52.65,52.65,0,0,0,43.45.08,8.51,8.51,0,0,1,10.42,2.72h0a8.5,8.5,0,0,1-3.35,12.74,69.57,69.57,0,0,1-57.63-.1,8.5,8.5,0,0,1-3.34-12.73h0A8.52,8.52,0,0,1,242.55,306.09Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("PHQ9")
                }
              }
            }),
            _c("path", {
              staticClass: "moderate",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.PHQ9.value >= 2
                    ? _vm.PHQ9.color
                    : "#ededed",
                d:
                  "M264.78,354.12a93.9,93.9,0,0,1-44-10.83,8.44,8.44,0,0,1-2.94-12.43l.1-.14a8.37,8.37,0,0,1,10.67-2.52,77.71,77.71,0,0,0,72.3,0,8.37,8.37,0,0,1,10.67,2.52l.1.14a8.44,8.44,0,0,1-2.94,12.43A93.9,93.9,0,0,1,264.78,354.12Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("PHQ9")
                }
              }
            }),
            _c("path", {
              staticClass: "high",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.PHQ9.value >= 3
                    ? _vm.PHQ9.color
                    : "#ededed",
                d:
                  "M264.78,379.12a119,119,0,0,1-59-15.53A8.45,8.45,0,0,1,203,351.28l.12-.16a8.46,8.46,0,0,1,11.06-2.32,102.69,102.69,0,0,0,101.16,0,8.46,8.46,0,0,1,11.06,2.32l.12.16a8.45,8.45,0,0,1-2.71,12.31A119,119,0,0,1,264.78,379.12Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("PHQ9")
                }
              }
            })
          ])
        : _vm._e(),
      _vm.GAD7
        ? _c("g", { staticClass: "GAD7" }, [
            _vm.showLabels
              ? _c(
                  "text",
                  {
                    staticClass: "label",
                    attrs: {
                      fill: _vm.GAD7.color,
                      transform: "translate(321.68 191.82)"
                    },
                    on: {
                      click: function($event) {
                        return _vm.onSelect("GAD7")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.GAD7.assessmentLabel))]
                )
              : _vm._e(),
            _vm.showLabels
              ? _c(
                  "text",
                  {
                    staticClass: "value",
                    attrs: { transform: "translate(321.68 213.08)" }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.mode === _vm.modes.completed ? _vm.GAD7.label : ""
                      )
                    )
                  ]
                )
              : _vm._e(),
            _c("path", {
              staticClass: "very-low",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.GAD7.value >= 0
                    ? _vm.GAD7.color
                    : "#ededed",
                d:
                  "M287.07,274.51a27.68,27.68,0,0,0,4.86-14.84,8.42,8.42,0,0,1,5.86-7.66h0a8.49,8.49,0,0,1,11.12,8.32A44.53,44.53,0,0,1,301.14,284a8.49,8.49,0,0,1-13.87.16h0A8.42,8.42,0,0,1,287.07,274.51Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("GAD7")
                }
              }
            }),
            _c("path", {
              staticClass: "low",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.GAD7.value >= 1
                    ? _vm.GAD7.color
                    : "#ededed",
                d:
                  "M303.14,294.37a52.66,52.66,0,0,0,13.5-41.3,8.52,8.52,0,0,1,5.81-9.07h0a8.51,8.51,0,0,1,11.08,7.13,69.61,69.61,0,0,1-17.91,54.78,8.5,8.5,0,0,1-13.13-.77h0A8.52,8.52,0,0,1,303.14,294.37Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("GAD7")
                }
              }
            }),
            _c("path", {
              staticClass: "moderate",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.GAD7.value >= 2
                    ? _vm.GAD7.color
                    : "#ededed",
                d:
                  "M318.3,314.63a77.62,77.62,0,0,0,22.52-69,8.46,8.46,0,0,1,5.76-9.44h0a8.52,8.52,0,0,1,11,6.72,94.55,94.55,0,0,1-27.35,83.84,8.53,8.53,0,0,1-12.86-1.05h0A8.45,8.45,0,0,1,318.3,314.63Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("GAD7")
                }
              }
            }),
            _c("path", {
              staticClass: "high",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.GAD7.value >= 3
                    ? _vm.GAD7.color
                    : "#ededed",
                d:
                  "M333.43,334.72a102.57,102.57,0,0,0,31.45-96.47,8.53,8.53,0,0,1,5.67-9.88h0a8.5,8.5,0,0,1,10.95,6.35,119.41,119.41,0,0,1-36.7,112.68,8.5,8.5,0,0,1-12.58-1.32l0,0A8.54,8.54,0,0,1,333.43,334.72Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("GAD7")
                }
              }
            })
          ])
        : _vm._e(),
      _vm.STRESS
        ? _c("g", { staticClass: "STRESS" }, [
            _vm.showLabels
              ? _c(
                  "text",
                  {
                    staticClass: "label",
                    attrs: {
                      fill: _vm.STRESS.color,
                      transform: "translate(10 191.82)"
                    },
                    on: {
                      click: function($event) {
                        return _vm.onSelect("STRESS")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.STRESS.assessmentLabel) + "\n    ")]
                )
              : _vm._e(),
            _vm.showLabels
              ? _c(
                  "text",
                  {
                    staticClass: "value",
                    attrs: { transform: "translate(25 213.08)" }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.mode === _vm.modes.completed ? _vm.STRESS.label : ""
                      )
                    )
                  ]
                )
              : _vm._e(),
            _c("path", {
              staticClass: "very-low",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.STRESS.value >= 0
                    ? _vm.STRESS.color
                    : "#ededed",
                d:
                  "M237.36,258.71a27.6,27.6,0,0,0,4.78,14.86,8.39,8.39,0,0,1-.23,9.64h0A8.49,8.49,0,0,1,228,283a44.69,44.69,0,0,1-7.64-23.76A8.5,8.5,0,0,1,231.5,251h0A8.42,8.42,0,0,1,237.36,258.71Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("STRESS")
                }
              }
            }),
            _c("path", {
              staticClass: "low",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.STRESS.value >= 1
                    ? _vm.STRESS.color
                    : "#ededed",
                d:
                  "M212.68,252.09A52.65,52.65,0,0,0,226,293.43a8.53,8.53,0,0,1,.64,10.76h0a8.5,8.5,0,0,1-13.15.75A69.6,69.6,0,0,1,195.8,250.1,8.5,8.5,0,0,1,206.88,243h0A8.51,8.51,0,0,1,212.68,252.09Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("STRESS")
                }
              }
            }),
            _c("path", {
              staticClass: "moderate",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.STRESS.value >= 2
                    ? _vm.STRESS.color
                    : "#ededed",
                d:
                  "M188.51,244.61a77.57,77.57,0,0,0,22.35,69.08,8.45,8.45,0,0,1,.89,11h0a8.52,8.52,0,0,1-12.87,1,94.54,94.54,0,0,1-27.15-83.91,8.52,8.52,0,0,1,11-6.7h0A8.45,8.45,0,0,1,188.51,244.61Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("STRESS")
                }
              }
            }),
            _c("path", {
              staticClass: "high",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed && _vm.STRESS.value >= 3
                    ? _vm.STRESS.color
                    : "#ededed",
                d:
                  "M164.46,237.26a102.53,102.53,0,0,0,31.26,96.52,8.54,8.54,0,0,1,1.22,11.33h0a8.5,8.5,0,0,1-12.59,1.3A119.41,119.41,0,0,1,147.8,233.68a8.51,8.51,0,0,1,10.95-6.33h0A8.54,8.54,0,0,1,164.46,237.26Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("STRESS")
                }
              }
            })
          ])
        : _vm._e(),
      _vm.$data["CD-RISC2"]
        ? _c("g", { staticClass: "CD-RISC2" }, [
            _vm.showLabels
              ? _c(
                  "text",
                  {
                    staticClass: "label",
                    attrs: {
                      fill: _vm.$data["CD-RISC2"].color,
                      transform: "translate(42.85 15.42)"
                    },
                    on: {
                      click: function($event) {
                        return _vm.onSelect("CD-RISC2")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$data["CD-RISC2"].assessmentLabel))]
                )
              : _vm._e(),
            _vm.showLabels
              ? _c(
                  "text",
                  {
                    staticClass: "value",
                    attrs: { transform: "translate(42.36 37.52)" }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.mode === _vm.modes.completed
                          ? _vm.$data["CD-RISC2"].label
                          : ""
                      )
                    )
                  ]
                )
              : _vm._e(),
            _c("path", {
              staticClass: "very-low",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed &&
                  _vm.$data["CD-RISC2"].value >= 0
                    ? _vm.$data["CD-RISC2"].color
                    : "#ededed",
                d:
                  "M229.28,231.83a44.64,44.64,0,0,1,20.15-14.72,8.49,8.49,0,0,1,11.35,8h0a8.41,8.41,0,0,1-5.47,7.94,27.69,27.69,0,0,0-12.61,9.22,8.44,8.44,0,0,1-9.28,2.8h0A8.5,8.5,0,0,1,229.28,231.83Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("CD-RISC2")
                }
              }
            }),
            _c("path", {
              staticClass: "low",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed &&
                  _vm.$data["CD-RISC2"].value >= 1
                    ? _vm.$data["CD-RISC2"].color
                    : "#ededed",
                d:
                  "M204,224.82a69.62,69.62,0,0,1,46.57-34,8.51,8.51,0,0,1,10.2,8.33h0a8.54,8.54,0,0,1-6.83,8.33,52.62,52.62,0,0,0-35.11,25.6,8.53,8.53,0,0,1-10,4h0A8.51,8.51,0,0,1,204,224.82Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("CD-RISC2")
                }
              }
            }),
            _c("path", {
              staticClass: "moderate",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed &&
                  _vm.$data["CD-RISC2"].value >= 2
                    ? _vm.$data["CD-RISC2"].color
                    : "#ededed",
                d:
                  "M179.69,217.33A94.59,94.59,0,0,1,251,165.41a8.52,8.52,0,0,1,9.8,8.4h0a8.46,8.46,0,0,1-7.2,8.4A77.57,77.57,0,0,0,194.9,225a8.46,8.46,0,0,1-10.22,4.27h0A8.52,8.52,0,0,1,179.69,217.33Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("CD-RISC2")
                }
              }
            }),
            _c("path", {
              staticClass: "high",
              attrs: {
                fill:
                  _vm.mode === _vm.modes.completed &&
                  _vm.$data["CD-RISC2"].value >= 3
                    ? _vm.$data["CD-RISC2"].color
                    : "#ededed",
                d:
                  "M155.54,209.88a119.39,119.39,0,0,1,95.82-69.72,8.5,8.5,0,0,1,9.42,8.45h0a8.54,8.54,0,0,1-7.64,8.45,102.54,102.54,0,0,0-82,59.71,8.54,8.54,0,0,1-10.41,4.67h0A8.5,8.5,0,0,1,155.54,209.88Z",
                transform: "translate(-66.11 -121.87)"
              },
              on: {
                click: function($event) {
                  return _vm.onSelect("CD-RISC2")
                }
              }
            })
          ])
        : _vm._e(),
      _vm.showAnimation
        ? _c("circle", {
            staticClass: "innerCircle",
            attrs: { cx: "199px", cy: "137px", r: "100%", fill: "#fafafa" }
          })
        : _vm._e(),
      _vm.mode === _vm.modes.due
        ? _c("circle", {
            attrs: {
              cx: "199",
              cy: "137",
              r: "129",
              fill: "transparent",
              stroke: "url(#paint0_linear_4758_38099)",
              "stroke-width": "5"
            }
          })
        : _vm._e(),
      _c(
        "defs",
        { attrs: { xmlns: "http://www.w3.org/2000/svg" } },
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear_4758_38099",
                x1: "375.367",
                y1: "105.09",
                x2: "375.367",
                y2: "309.082",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "var(--StarlingSuccess)" } }),
              _c("stop", {
                attrs: { offset: "1", "stop-color": "var(--StarlingPrimary2)" }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }