import { Module } from 'vuex';
import { RootState } from '@/scripts/store/types';
import { ISleepTracker, ISleepWindow, SleepTrackState } from './types';

const intialState: SleepTrackState = {
  sleepTrackList: [],
  sleepWindow: undefined,
};

export const defaultState: SleepTrackState = {
  ...intialState,
};

export const sleep: Module<SleepTrackState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    resetSleepTrackState(state) {
      Object.assign(state, intialState);
    },
    sleepTrackList(state, sleepTrackList: ISleepTracker[]) {
      state.sleepTrackList = sleepTrackList;
    },
  },
  actions: {
    getSleepTrackList({ commit, dispatch }) {
      const callback = (res: ISleepTracker[]) => {
        commit('sleepTrackList', res);
        return res;
      };
      return dispatch('$apiRequest', [ '/tools/sleep', callback ], { root: true });
    },
    getSleepTrack({ commit, dispatch }, id: string) {
      const callback = (res: ISleepTracker) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/sleep/${id}`, callback ], { root: true });
    },
    createSleepTrack({ commit, dispatch }, sleep: ISleepTracker) {
      const callback = (res: ISleepTracker) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/sleep`, callback, 'post', sleep ], { root: true });
    },
    editSleepTrack({ commit, dispatch }, sleep: ISleepTracker) {
      const callback = (res: ISleepTracker) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/sleep/${sleep.id}`, callback, 'put', sleep ], { root: true });
    },
    deleteSleepTrack({ commit, dispatch }, id: string) {
      return dispatch('$apiRequest', [ `/tools/sleep/${id}`, null, 'delete' ], { root: true });
    },
    getSleepWindow({ commit, dispatch }) {
      const callback = (res: ISleepWindow | undefined) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/sleep/window`, callback, 'get' ], { root: true });
    },
    setSleepWindow({ commit, dispatch }, sleepWindow: ISleepWindow) {
      const callback = (res: ISleepWindow | undefined) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/sleep/window`, callback, 'put', sleepWindow ], { root: true });
    },
  },
  getters: {
    sleepTrackList(state): ISleepTracker[] {
      return state.sleepTrackList;
    },
  },
};
