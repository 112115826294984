































































































































































































































































































import Vue from 'vue';
import customConfirmationModal from '@/views/components/utilities/custom-confirmation-modal.vue';
import WebinarNotificationsWarning from '@/views/components/webinar/webinar-notifications-warning.vue';
import { WebinarRegistrant } from '@/scripts/store/modules/webinar/types';

export interface ProfileCommunication {
  type: string,
  commentReactions: boolean,
  commentReplies: boolean,
  sessionReminder: boolean,
  checkUpReminder: boolean,
  goalReminder: boolean,
  webinarReminder: boolean,
  eventCompletion: boolean,
  exerciseCompletion: boolean,
  newsletter: boolean,
}

export default Vue.extend({
  name: 'profile-communication',
  components: { WebinarNotificationsWarning, customConfirmationModal },
  data() {
    return {
      loading: false,
      showGoalReminderModal: false,
      goalReminderTriggerType: undefined as string | undefined,
      tempGoalReminderEmailValue: false,
      tempGoalReminderPushValue: false,
      showPushWarning: false,
      showWebinarWarning: false,
    };
  },
  computed: {
    user(): any {
      return this.$store.getters.user;
    },
    emailSettings: {
      get(): ProfileCommunication {
        return this.user.notifications.find((notification: ProfileCommunication) => notification.type === 'EMAIL');
      },
      set(newValue: ProfileCommunication): void {
        this.user.notifications = this.user.notifications.map((notification: ProfileCommunication) => {
          if (notification.type === 'EMAIL') {
            return newValue;
          }
          return notification;
        });
      },
    },
    isNativePlatform(): boolean {
      return this.$store.getters.isNativePlatform;
    },
    pushSettings: {
      get(): ProfileCommunication {
        return this.user.notifications.find((notification: ProfileCommunication) => notification.type === 'PUSH');
      },
      set(newValue: ProfileCommunication): void {
        this.user.notifications = this.user.notifications.map((notification: ProfileCommunication) => {
          if (notification.type === 'PUSH') {
            return newValue;
          }
          return notification;
        });
      },
    },
    internalSettings: {
      get(): ProfileCommunication {
        return this.user.notifications.find((notification: ProfileCommunication) => notification.type === 'INTERNAL');
      },
      set(newValue: ProfileCommunication): void {
        this.user.notifications = this.user.notifications.map((notification: ProfileCommunication) => {
          if (notification.type === 'INTERNAL') {
            return newValue;
          }
          return notification;
        });
      },
    },
  },
  methods: {
    updateWebinarCommunications(type: string, evt: boolean) {
      if (!evt) {
        this.$store.dispatch('webinar/getAvailableWebinars')
          .then((res: WebinarRegistrant[]) => {
            if (res.some(r => r.isRegistered)) {
              this.showWebinarWarning = true;
            } else {
              this.updateCommunications(type);
            }
          }).finally(() => {
            this.loading = false;
          });
      } else {
        this.updateCommunications(type);
      }
    },
    updateCommunications(type?: string) {
      if (this.loading) return;
      this.loading = true;
      this.$store.dispatch('editUser', {}).then().finally(() => {
        this.loading = false;
      });
      if (this.isNativePlatform && type === 'PUSH') {
        this.capacitorCheckPushPermission().catch(() => {
          this.showPushWarning = true;
        });
      }
    },
    handleGoalReminderSwitch(type?: string) {
      if (type === 'EMAIL') {
        this.tempGoalReminderEmailValue = !this.emailSettings.goalReminder;
        this.tempGoalReminderPushValue = this.pushSettings.goalReminder;
      } else {
        this.tempGoalReminderEmailValue = this.emailSettings.goalReminder;
        this.tempGoalReminderPushValue = !this.pushSettings.goalReminder;
      }
      if (!this.emailSettings.goalReminder && !this.pushSettings.goalReminder) {
        this.goalReminderTriggerType = type;
        this.showGoalReminderModal = true;
      } else {
        this.updateCommunications(type);
      }
    },
    closeGoalReminderModal() {
      this.emailSettings.goalReminder = this.tempGoalReminderEmailValue;
      this.pushSettings.goalReminder = this.tempGoalReminderPushValue;
      this.showGoalReminderModal = false;
      this.goalReminderTriggerType = undefined;
    },
    saveGoalReminder() {
      this.showGoalReminderModal = false;
      this.goalReminderTriggerType = undefined;
      this.updateCommunications();
    },
    onWebinarWarningConfirm() {
      this.showWebinarWarning = false;
      this.updateCommunications(undefined);
    },
    onWebinarWarningCancel() {
      this.$store.dispatch('getUser').finally(() => {
        this.showWebinarWarning = false;
      });
    },
  },
});
