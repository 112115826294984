var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-text",
    { staticClass: "sleep-track-form-calculate pa-3" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c("input", {
            attrs: { type: "hidden" },
            domProps: { value: _vm.internalValue.id }
          }),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "gap-xs-3": "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c(
                  "h3",
                  { staticClass: "starling-h3" },
                  [
                    _c("custom-icon", {
                      staticClass: "mr-1",
                      attrs: {
                        name: "sleeptracker",
                        fill: "none",
                        stroke: "none"
                      }
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("app.tools.sleep_tracker.title")) +
                        "\n        "
                    )
                  ],
                  1
                )
              ]),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs5: "" } }, [
                        _c("h4", { staticClass: "starling-h4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "app.tools.sleep_tracker.fields.date.label"
                              )
                            )
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs7: "" } },
                        [
                          _c("sm-input-date-field", {
                            attrs: {
                              required: "",
                              placeholder: _vm.$t(
                                "app.tools.sleep_tracker.fields.date.placeholder"
                              ),
                              maxDate: _vm.maxDate
                            },
                            model: {
                              value: _vm.internalValue.refDate,
                              callback: function($$v) {
                                _vm.$set(_vm.internalValue, "refDate", $$v)
                              },
                              expression: "internalValue.refDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs5: "" } }, [
                        _c("h4", { staticClass: "starling-h4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "app.tools.sleep_tracker.fields.in_bed.label"
                              )
                            )
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs7: "" } },
                        [
                          _c("sm-input-time-field", {
                            attrs: {
                              required: "",
                              minuteInterval: 5,
                              placeholder: _vm.$t(
                                "app.tools.sleep_tracker.fields.in_bed.placeholder"
                              )
                            },
                            model: {
                              value: _vm.internalValue.timeInBed,
                              callback: function($$v) {
                                _vm.$set(_vm.internalValue, "timeInBed", $$v)
                              },
                              expression: "internalValue.timeInBed"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs5: "" } }, [
                        _c("h4", { staticClass: "starling-h4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "app.tools.sleep_tracker.fields.asleep.label"
                              )
                            )
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs7: "" } },
                        [
                          _c("sm-input-time-field", {
                            attrs: {
                              required: "",
                              valid: _vm.validTimeAsleep,
                              minuteInterval: 5,
                              placeholder: _vm.$t(
                                "app.tools.sleep_tracker.fields.asleep.placeholder"
                              )
                            },
                            model: {
                              value: _vm.internalValue.timeAsleep,
                              callback: function($$v) {
                                _vm.$set(_vm.internalValue, "timeAsleep", $$v)
                              },
                              expression: "internalValue.timeAsleep"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs5: "" } }, [
                        _c("h4", { staticClass: "starling-h4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "app.tools.sleep_tracker.fields.woke_up.label"
                              )
                            )
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs7: "" } },
                        [
                          _c("sm-input-time-field", {
                            attrs: {
                              required: "",
                              valid: _vm.validTimeWokeUp,
                              minuteInterval: 5,
                              placeholder: _vm.$t(
                                "app.tools.sleep_tracker.fields.woke_up.placeholder"
                              )
                            },
                            model: {
                              value: _vm.internalValue.timeWokeUp,
                              callback: function($$v) {
                                _vm.$set(_vm.internalValue, "timeWokeUp", $$v)
                              },
                              expression: "internalValue.timeWokeUp"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs5: "" } }, [
                        _c("h4", { staticClass: "starling-h4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "app.tools.sleep_tracker.fields.out_bed.label"
                              )
                            )
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs7: "" } },
                        [
                          _c("sm-input-time-field", {
                            attrs: {
                              required: "",
                              valid: _vm.validTimeOutBed,
                              minuteInterval: 5,
                              placeholder: _vm.$t(
                                "app.tools.sleep_tracker.fields.out_bed.placeholder"
                              )
                            },
                            model: {
                              value: _vm.internalValue.timeOutBed,
                              callback: function($$v) {
                                _vm.$set(_vm.internalValue, "timeOutBed", $$v)
                              },
                              expression: "internalValue.timeOutBed"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.validTimes
                ? _c(
                    "v-flex",
                    { staticClass: "text-center", attrs: { xs12: "" } },
                    [
                      _c(
                        "p",
                        { staticClass: "starling-citation error--text" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "app.tools.sleep_tracker.notifications.invalid_time"
                              )
                            )
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "text-center": "" } },
                        [
                          _c("action-buttons", {
                            attrs: {
                              primary: {
                                label: _vm.$t(
                                  "app.tools.sleep_tracker.actions.calculate"
                                ),
                                disabled: !_vm.allFieldsFilled,
                                loading: _vm.loading
                              },
                              secondary: _vm.cancellable
                                ? {
                                    label: _vm.$t("common.actions.cancel"),
                                    disabled: _vm.loading
                                  }
                                : undefined
                            },
                            on: {
                              "primary-clicked": _vm.onSave,
                              "secondary-clicked": _vm.onCancel
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }