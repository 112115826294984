var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadedSleepTrack
    ? _c(
        "v-layout",
        { staticClass: "element-sleep-track", attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", lg9: "", "text-left": "" } },
            [
              !_vm.linkedSleepTrack.id || _vm.editing
                ? _c("sleep-track-form", {
                    attrs: {
                      value: _vm.linkedSleepTrack,
                      cancellable: _vm.editing,
                      inline: ""
                    },
                    on: { update: _vm.onUpdate, cancel: _vm.onCancel }
                  })
                : _c("sleep-track-card", {
                    attrs: { sleep: _vm.linkedSleepTrack },
                    on: { edit: _vm.onEdit, update: _vm.onUpdate }
                  })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }