var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showCommentReplyBanner
    ? _c(
        "v-flex",
        { attrs: { xs12: "", "mb-3": "" } },
        [
          _c(
            "v-layout",
            {
              staticClass: "comment-reply-banner",
              attrs: { "align-center": "" }
            },
            [
              _c(
                "v-hover",
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "cursor-pointer",
                      attrs: { "align-center": "" },
                      on: { click: _vm.onCardClick }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "", "px-2": "", "pt-1": "" } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticStyle: { height: "50px" },
                              attrs: { "x-large": "" }
                            },
                            [
                              _vm._v(
                                "$vuetify.icons.starling_comment_new_reply"
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { "align-content-start": "" } }, [
                        _c("h4", { staticClass: "starling-h4" }, [
                          _vm._v(_vm._s(_vm.bannerTitle))
                        ])
                      ]),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: {
                    dense: "",
                    bottom: "",
                    left: "",
                    "content-class": "menu"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                { attrs: { flat: "", icon: "", "mr-2": "" } },
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { medium: "" } }, [
                                  _vm._v("more_vert")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2568886907
                  )
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-tile",
                        {
                          on: {
                            click: function($event) {
                              return _vm.toggleModal("read")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-tile-action",
                            [
                              _c("v-icon", { attrs: { size: "md" } }, [
                                _vm._v(
                                  "$vuetify.icons.starling_comment_new_reply_draft"
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-tile-title",
                            {
                              staticClass:
                                "starling-explanatory StarlingGrey--text"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "app.community.new_reply_banner.mark_read"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile",
                        {
                          on: {
                            click: function($event) {
                              return _vm.toggleModal("hide")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-tile-action",
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "md", color: "#3A5981" } },
                                [_vm._v("visibility_off")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-tile-title",
                            {
                              staticClass:
                                "starling-explanatory StarlingGrey--text"
                            },
                            [_vm._v(_vm._s(_vm.replyButton))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("custom-confirmation-modal", {
            attrs: {
              display: _vm.showMarkRead,
              title: _vm.$t("app.community.new_reply_banner.mark_read"),
              description: _vm.$t(
                "app.community.new_reply_banner.mark_read_description"
              ),
              primaryConfirmLabel: _vm.$t("common.actions.continue"),
              secondaryCancelLabel: _vm.$t("common.actions.cancel")
            },
            on: {
              "cancel-event": function($event) {
                return _vm.toggleModal("read")
              },
              "confirm-event": _vm.markReadConfirmed
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }