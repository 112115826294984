


































import Vue from 'vue';
import { ISleepTracker } from '@/scripts/store/modules/tools/sleep/types';
import SleepTrackList from '@/views/tools/sleep/components/sleep-track-list.vue';
import primaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'sleepTrackView',
  components: {
    SleepTrackList,
    primaryButton,
  },
  data() {
    return {
      creating: false,
      loading: false,
      form: {},
      createMode: false,
    };
  },
  computed: {
    storeSleepTrack(): ISleepTracker[] {
      return this.$store.getters['sleep/sleepTrackList'];
    },
    hasSleepTrack(): boolean {
      return !!this.storeSleepTrack?.length;
    },
  },
  mounted() {
    this.loading = true;
    this.refreshSleepTrack();
  },
  methods: {
    refreshSleepTrack() {
      this.$store.dispatch('sleep/getSleepTrackList').finally(() => {
        this.loading = false;
      });
    },
    onCreate() {
      this.$router.push({ name: 'sleep-track-create' });
    },
    onEdit(sleep: ISleepTracker) {
      this.$router.push({ name: 'sleep-track-edit', params: { id: sleep.id as string } });
    },
  },
});
