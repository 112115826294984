var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-text",
    { staticClass: "sleep-track-form-score pa-3" },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "gap-xs-3": "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c(
              "h3",
              { staticClass: "starling-h3" },
              [
                _c("custom-icon", {
                  staticClass: "mr-1",
                  attrs: { name: "sleeptracker", fill: "none", stroke: "none" }
                }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("app.tools.sleep_tracker.title")) +
                    "\n      "
                )
              ],
              1
            )
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { grow: "" } }, [
                    _c("h5", { staticClass: "starling-h5" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.tools.sleep_tracker.sleep_card.content.date"
                          )
                        )
                      )
                    ])
                  ]),
                  _c("v-flex", { attrs: { shrink: "" } }, [
                    _c(
                      "h5",
                      { staticClass: "starling-h5 StarlingDarkGreen--text" },
                      [
                        _vm._v(
                          _vm._s(_vm.formatDate(_vm.internalValue.refDate))
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "align-baseline": "" } },
                [
                  _c("v-flex", { attrs: { grow: "" } }, [
                    _c("h5", { staticClass: "starling-h5" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.tools.sleep_tracker.sleep_card.content.in_bed"
                          )
                        )
                      )
                    ])
                  ]),
                  _c("v-flex", { attrs: { shrink: "" } }, [
                    _c(
                      "h5",
                      { staticClass: "starling-h5 StarlingDarkGreen--text" },
                      [
                        _vm._v(
                          _vm._s(_vm.formatTime(_vm.internalValue.timeInBed))
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "align-baseline": "" } },
                [
                  _c("v-flex", { attrs: { grow: "" } }, [
                    _c("h5", { staticClass: "starling-h5" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.tools.sleep_tracker.sleep_card.content.asleep"
                          )
                        )
                      )
                    ])
                  ]),
                  _c("v-flex", { attrs: { shrink: "" } }, [
                    _c(
                      "h5",
                      { staticClass: "starling-h5 StarlingDarkGreen--text" },
                      [
                        _vm._v(
                          _vm._s(_vm.formatTime(_vm.internalValue.timeAsleep))
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "align-baseline": "" } },
                [
                  _c("v-flex", { attrs: { grow: "" } }, [
                    _c("h5", { staticClass: "starling-h5" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.tools.sleep_tracker.sleep_card.content.woke_up"
                          )
                        )
                      )
                    ])
                  ]),
                  _c("v-flex", { attrs: { shrink: "" } }, [
                    _c(
                      "h5",
                      { staticClass: "starling-h5 StarlingDarkGreen--text" },
                      [
                        _vm._v(
                          _vm._s(_vm.formatTime(_vm.internalValue.timeWokeUp))
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "align-baseline": "" } },
                [
                  _c("v-flex", { attrs: { grow: "" } }, [
                    _c("h5", { staticClass: "starling-h5" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.tools.sleep_tracker.sleep_card.content.out_bed"
                          )
                        )
                      )
                    ])
                  ]),
                  _c("v-flex", { attrs: { shrink: "" } }, [
                    _c(
                      "h5",
                      { staticClass: "starling-h5 StarlingDarkGreen--text" },
                      [
                        _vm._v(
                          _vm._s(_vm.formatTime(_vm.internalValue.timeOutBed))
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("h5", { staticClass: "starling-h5" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "app.tools.sleep_tracker.sleep_card.content.sleep_score"
                  )
                )
              )
            ])
          ]),
          _vm.internalValue.efficiencyScore !== undefined
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", { staticClass: "sleep-total-score" }, [
                          _c(
                            "h1",
                            {
                              staticClass: "starling-h1",
                              style: _vm.totalScoreStyle
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.internalValue.efficiencyScore) + "%"
                              )
                            ]
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-progress-linear",
                            {
                              staticClass: "sleep-score-bar ma-0 radius-10",
                              attrs: {
                                value: _vm.internalValue.efficiencyScore,
                                height: "18"
                              }
                            },
                            [_c("div", { staticClass: "threshold" })]
                          )
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", { staticClass: "sleep-score-target" }, [
                          _c("p", { staticClass: "starling-body" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "app.tools.sleep_tracker.sleep_card.content.target"
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "align-baseline": "", "gap-xs-2": "" } },
                [
                  _c("v-flex", { attrs: { shrink: "" } }, [
                    _c("h5", { staticClass: "starling-h5" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.tools.sleep_tracker.sleep_card.content.time_asleep"
                          )
                        )
                      )
                    ])
                  ]),
                  _vm.totalAsleep
                    ? _c("v-flex", { attrs: { grow: "" } }, [
                        _c(
                          "h5",
                          {
                            staticClass: "starling-h5 StarlingDarkGreen--text"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "app.tools.sleep_tracker.sleep_card.content.duration",
                                  _vm.totalAsleep
                                )
                              )
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("input-note-wrapper-element", {
                attrs: { note: _vm.note, loading: _vm.loading },
                on: { updated: _vm.onNoteUpdated }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "text-center", attrs: { xs12: "" } },
            [
              _c("action-buttons", {
                attrs: {
                  primary: { label: _vm.$t("common.actions.save") },
                  secondary: { label: _vm.$t("common.actions.edit") }
                },
                on: {
                  "primary-clicked": _vm.onSave,
                  "secondary-clicked": _vm.onEdit
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }