













































































import Vue from 'vue';
import { ProfileCommunication } from '@/views/profile/partials/profile-communication.vue';
import {
  IHomeDailyProgress,
  IHomeDailyProgressCheckup,
  ProgressTrackerModes,
} from '@/scripts/store/modules/home/types';
import CheckupResultsRadarchart from '@/views/home/components/checkup-results-radarchart.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import { mapState } from 'vuex';
import HomeCheckupChosenDate from '@/views/home/steps/home-checkup-chosen-date.vue';

export default Vue.extend({
  name: 'home-track-progress',
  components: {
    HomeCheckupChosenDate,
    PrimaryButton,
    CheckupResultsRadarchart,
  },
  data() {
    return {
      loading: false,
      Modes: ProgressTrackerModes,
      assessmentChart: {
        values: {
          ENERGY: {
            simplifiedValue: 'NA',
          },
          'CD-RISC2': {
            simplifiedValue: 'NA',
          },
          GAD7: {
            simplifiedValue: 'NA',
          },
          PHQ9: {
            simplifiedValue: 'NA',
          },
          STRESS: {
            simplifiedValue: 'NA',
          },
        },
      },
    };
  },
  computed: {
    dailyProgress(): IHomeDailyProgress {
      return this.$store.getters['home/dailyProgress'] as IHomeDailyProgress;
    },
    checkup(): IHomeDailyProgressCheckup {
      return this.dailyProgress?.checkUp;
    },
    ...mapState({
      currentLanguage() {
        return this.$store.getters.currentLanguage;
      },
    }),
    user(): any {
      return this.$store.getters.user;
    },
    internalSettings: {
      get(): ProfileCommunication {
        return this.user.notifications.find((notification: ProfileCommunication) => notification.type === 'INTERNAL');
      },
      set(newValue: ProfileCommunication): void {
        this.user.notifications = this.user.notifications.map((notification: ProfileCommunication) => {
          if (notification.type === 'INTERNAL') {
            return newValue;
          }
          return notification;
        });
      },
    },
  },
  watch: {
    checkup: {
      immediate: true,
      deep: true,
      handler(newCheckup) {
        if (newCheckup?.status === ProgressTrackerModes.completed) {
          this.$store.dispatch('assessment/getMostRecentAssessments').then(res => {
            this.assessmentChart.values = res;
          });
        }
      },
    },
  },
  methods: {
    goToCheckup() {
      this.$router.push({ name: 'checkup' });
    },
    goToCheckupResults() {
      this.$router.push({ name: 'checkup-results' });
    },
    clickRadarChart() {
      if (this.checkup.status === ProgressTrackerModes.completed) {
        this.goToCheckupResults();
      } else {
        this.goToCheckup();
      }
    },
    onHide() {
      this.loading = true;
      this.internalSettings.checkUpReminder = !this.internalSettings.checkUpReminder;
      this.$store.dispatch('editUser', {}).then().finally(() => {
        this.loading = false;
      });
    },
  },
});
