import { render, staticRenderFns } from "./home-step.vue?vue&type=template&id=5fe656fc&scoped=true&"
import script from "./home-step.vue?vue&type=script&lang=ts&"
export * from "./home-step.vue?vue&type=script&lang=ts&"
import style0 from "./home-step.vue?vue&type=style&index=0&id=5fe656fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fe656fc",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VExpandTransition,VFlex,VHover,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2439010145/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5fe656fc')) {
      api.createRecord('5fe656fc', component.options)
    } else {
      api.reload('5fe656fc', component.options)
    }
    module.hot.accept("./home-step.vue?vue&type=template&id=5fe656fc&scoped=true&", function () {
      api.rerender('5fe656fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/steps/home-step.vue"
export default component.exports