



























import Vue from 'vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import TimePicker from '@/views/components/utilities/time-picker.vue';
import store from '@/scripts/store';
import { format, parse } from 'date-fns';

export const FORMAT_HHMM = 'HH:mm';

export default Vue.extend({
  name: 'sm-input-time-field',
  components: { PrimaryButton, TimePicker },
  props: {
    value: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    valid: {
      type: Boolean,
      required: false,
      default: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    minuteInterval: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      showDialog: false,
      internalValue: undefined as string | undefined,
      rules: {
        required: [ (v: string) => !!v || this.$t('common.errors.required') ],
      },
    };
  },
  computed: {
    currentLocale() {
      return store.getters.getCurrentLocale;
    },
    timeFormat() {
      return store.getters.getHourFormat;
    },
    formattedTime: {
      get(): string | null {
        return this.internalValue ? format(parse(this.internalValue, FORMAT_HHMM, new Date()), this.currentLocale.formatLong?.time({ width: 'short' }), { locale: this.currentLocale }) : null;
      },
      set(newValue: string) {
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue === oldValue) return;
        this.internalValue = newValue;
      },
    },
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.internalValue = this.value;
    },
    save() {
      this.showDialog = false;
      this.$emit('input', this.internalValue);
    },
  },
});
