var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-layout",
        {
          attrs: {
            row: "",
            wrap: "",
            "justify-center": "",
            "align-center": "",
            "gap-xs-3": ""
          }
        },
        [
          _vm._l(_vm.sleepTrackPage, function(sleep) {
            return _c(
              "v-flex",
              { key: sleep.id, attrs: { xs12: "" } },
              [
                _c("sleep-track-card", {
                  attrs: { sleep: sleep, showHeader: "" },
                  on: { edit: _vm.onEdit, update: _vm.onUpdate }
                })
              ],
              1
            )
          }),
          _c(
            "v-flex",
            { attrs: { xs12: "", "text-center": "" } },
            [
              _vm.sleepTrack.length > 5
                ? _c("v-pagination", {
                    attrs: {
                      length: _vm.paginateLength,
                      "total-visible": "5",
                      circle: "",
                      light: ""
                    },
                    on: {
                      input: function($event) {
                        _vm.page = $event
                      }
                    },
                    model: {
                      value: _vm.page,
                      callback: function($$v) {
                        _vm.page = $$v
                      },
                      expression: "page"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }