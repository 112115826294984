

























import Vue from 'vue';
import { ISleepTracker } from '@/scripts/store/modules/tools/sleep/types';
import sleepTrackCard from '@/views/tools/sleep/components/sleep-track-card.vue';

export default Vue.extend({
  name: 'sleep-track-list',
  components: {
    sleepTrackCard,
  },
  props: {
    sleepTrack: {
      type: Array as () => Array<ISleepTracker>,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    sleepTrackPage(): Array<ISleepTracker> {
      if (!this.sleepTrack.length) return [];
      const iniPos = (this.page - 1) * 5;
      const endPos = this.page * 5;
      return this.sleepTrack.slice(iniPos, endPos);
    },
    paginateLength(): number {
      if (!this.sleepTrack.length) return 1;
      return Math.floor((this.sleepTrack.length) / 5) + ((this.sleepTrack.length % 5) && 1);
    },
  },
  methods: {
    onEdit(sleep: ISleepTracker) {
      this.$emit('edit', sleep);
    },
    onUpdate() {
      this.$emit('update');
    },
  },
});
