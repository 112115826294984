























































import Vue from 'vue';

interface ProfileCommunication {
  type: string,
  commentReactions: boolean,
  commentReplies: boolean,
  sessionReminder: boolean,
  checkUpReminder: boolean,
  goalReminder: boolean,
  webinarReminder: boolean,
  eventCompletion: boolean,
  exerciseCompletion: boolean,
  newsletter: boolean,
}

export default Vue.extend({
  name: 'profile-home-settings',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    user(): any {
      return this.$store.getters.user;
    },
    internalSettings: {
      get(): ProfileCommunication {
        return this.user.notifications.find((notification: ProfileCommunication) => notification.type === 'INTERNAL');
      },
      set(newValue: ProfileCommunication): void {
        this.user.notifications = this.user.notifications.map((notification: ProfileCommunication) => {
          if (notification.type === 'INTERNAL') {
            return newValue;
          }
          return notification;
        });
      },
    },
  },
  methods: {
    updateCommunications(type?: string) {
      if (this.loading) return;
      this.loading = true;
      this.$store.dispatch('editUser', {}).then().finally(() => {
        this.loading = false;
      });
    },
  },
});
