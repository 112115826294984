var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-text",
    { staticClass: "sleep-track-card-content pa-3" },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "gap-xs-3": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "align-baseline": "", "gap-xs-2": "" } },
                [
                  _c("v-flex", { attrs: { shrink: "" } }, [
                    _c("h4", { staticClass: "starling-h4" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.tools.sleep_tracker.sleep_card.content.sleep_score"
                          )
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { staticClass: "text-right", attrs: { grow: "" } },
                    [
                      _c(
                        "h4",
                        { staticClass: "starling-h4 StarlingDarkGreen--text" },
                        [_vm._v(_vm._s(_vm.sleep.efficiencyScore) + "%")]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "align-baseline": "", "gap-xs-2": "" } },
                [
                  _c("v-flex", { attrs: { shrink: "" } }, [
                    _c("h4", { staticClass: "starling-h4" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.tools.sleep_tracker.sleep_card.content.time_asleep"
                          )
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { staticClass: "text-right", attrs: { grow: "" } },
                    [
                      _c(
                        "h4",
                        { staticClass: "starling-h4 StarlingDarkGreen--text" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "app.tools.sleep_tracker.sleep_card.content.duration",
                                _vm.totalAsleep
                              )
                            )
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("input-note-wrapper-element", {
                attrs: { note: _vm.note, loading: _vm.loading },
                on: { updated: _vm.onNoteUpdated }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }