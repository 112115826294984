var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "profile-communication radius-15 pa-2",
      attrs: { elevation: "3" }
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", [
            _c("h3", { staticClass: "starling-h3" }, [
              _vm._v(_vm._s(_vm.$t("app.profile.home_settings.title")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-1": "" } },
            [_c("v-divider")],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "mt-2": "", "align-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-2": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "align-content-center": "" } },
                [
                  _c("v-flex", { attrs: { xs6: "", sm8: "" } }, [
                    _c("p", { staticClass: "starling-body" }, [
                      _vm._v(
                        _vm._s(_vm.$t("app.profile.home_settings.tiles.title"))
                      )
                    ])
                  ]),
                  _c("v-flex", { attrs: { xs6: "", sm4: "" } }, [
                    _c(
                      "p",
                      { staticClass: "starling-body StarlingPrimary2--text" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("app.profile.home_settings.tiles.action")
                          )
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "align-center": "" } },
                [
                  _c("v-flex", { attrs: { xs6: "", sm8: "" } }, [
                    _c("p", { staticClass: "starling-text text--disabled" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.profile.home_settings.tiles.community_notification"
                          )
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", sm4: "" } },
                    [
                      _c("v-switch", {
                        attrs: { loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("INTERNAL")
                          }
                        },
                        model: {
                          value: _vm.internalSettings.commentReplies,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.internalSettings,
                              "commentReplies",
                              $$v
                            )
                          },
                          expression: "internalSettings.commentReplies"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", "align-center": "" } },
                [
                  _c("v-flex", { attrs: { xs6: "", sm8: "" } }, [
                    _c("p", { staticClass: "starling-text text--disabled" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("app.profile.home_settings.tiles.my_progress")
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", sm4: "" } },
                    [
                      _c("v-switch", {
                        attrs: { loading: _vm.loading },
                        on: {
                          change: function($event) {
                            return _vm.updateCommunications("INTERNAL")
                          }
                        },
                        model: {
                          value: _vm.internalSettings.checkUpReminder,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.internalSettings,
                              "checkUpReminder",
                              $$v
                            )
                          },
                          expression: "internalSettings.checkUpReminder"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }