import store from '@/scripts/store/index';

export default {
  header_title: 'Herramientas',
  title: '¿Qué quieres hacer hoy?',
  home_title: 'Herramientas de TCC',
  home_subtitle: 'Una vez que hayas aprendido los conceptos básicos, utiliza las herramientas de TCC para perfeccionar tus habilidades.',
  content: {
    CHECKUP: {
      id: 'CHECKUP',
      icon: '$vuetify.icons.starling_checkup',
      title: 'Chequeo',
      subtitle: 'Realiza un nuevo Chequeo para monitorear cualquier cambio en tu salud mental.',
      route: { name: 'products-run', params: { uen: process.env.VUE_APP_CHECK_UP_UEN } },
    },
    CHECKUP_RESULTS: {
      id: 'CHECKUP_RESULTS',
      title: 'Mi progreso',
      subtitle: 'Revisa tu progreso para descubrir tus patrones.',
      route: { name: 'products-run', params: { uen: process.env.VUE_APP_CHECK_UP_RESULT_UEN } },
      iconComponent: {
        name: 'checkup-results-radarchart',
        isValid: (props) => {
          return props && props.values && Object.keys(props.values).map(k => props.values[k].simplifiedValue).every(v => v !== 'NOT_EVALUATED');
        },
        propsLoader: () => {
          return store
            .dispatch('assessment/getMostRecentAssessments')
            .then((res) => {
              if (res.status >= 400) {
                return { values: {}, interpretations: {} };
              }
              return { values: res, interpretations: {} };
            });
        },
      },
    },
    THOUGHT_BALANCER: {
      id: 'THOUGHT_BALANCER',
      icon: '$vuetify.icons.starling_thought_balancer',
      title: 'Equilibrador de pensamientos',
      subtitle: 'Crea posiciones equilibradas para controlar los pensamientos preocupantes o repetitivos.',
      route: {
        name: 'thoughts-list',
      },
      routeLabel: 'Consultar los pensamientos',
      cardImage: 'thought_balancer.svg',
    },
    GOAL_SETTER: {
      id: 'GOAL_SETTER',
      icon: '$vuetify.icons.starling_goal_setter',
      title: 'Establecedor de objetivos',
      subtitle: 'Establece objetivos SMART alcanzables y celebra tus éxitos.',
      route: {
        name: 'goals-list',
      },
      routeLabel: 'Consultar los objetivos',
      cardImage: 'goal_setter.svg',
    },
    SLEEP: {
      id: 'SLEEP',
      icon: '$vuetify.icons.starling_sleep_tracker',
      title: 'Eficacia del Sueño',
      subtitle: 'Monitorea la eficacia de tu sueño para controlar tu progreso.',
      route: {
        name: 'sleep-track-list',
      },
      routeLabel: 'Consultar la eficacia del sueño',
      cardImage: 'goal_setter.svg',
    },
    JOURNAL: {
      id: 'JOURNAL',
      icon: '$vuetify.icons.starling_workbook',
      title: 'Mi diario',
      subtitle: 'Anota tus reflexiones, ideas y pensamientos durante tu capacitación.',
      route: {
        name: 'journals-list',
      },
      routeLabel: 'Consultar el cuaderno de trabajo',
      cardImage: 'my_journal.svg',
    },
    MOOD_TRACKER: {
      id: 'MOOD_TRACKER',
      icon: '$vuetify.icons.starling_mood_tracker',
      title: 'Monitor de estado de ánimo',
      subtitle: 'Rompe los patrones dañinos al monitorear tus desencadenantes, estados de ánimo y reacciones comunes.',
      route: {
        name: 'moods-list',
      },
      routeLabel: 'Consultar los estados de ánimo',
      cardImage: 'mood_tracker.svg',
    },
  },

  goal_setter: {
    header_title: 'Establecedor de objetivos',
    title_first: 'Establezca tu primer objetivo, monitorea tu progreso y realiza cambios duraderos.',
    title_other: 'Establezca mejor objetivos, monitorea tu progreso y realiza cambios duraderos.',
    actions: {
      save: 'Guardar objetivo',
      create: 'Crear un objetivo',
    },
    fields: {
      title: {
        label: 'Mi objetivo:',
        placeholder: 'Añadir un título',
        copy: 'Copia: ',
      },
      task: {
        title: 'Tarea (opcional):',
        placeholder: 'Añadir una tarea',
        guideContent: '¡Buen objetivo! Si quieres reducirlo aún más, intenta establecer otro objetivo.',
      },
      startDate: {
        title: 'Hora de inicio:',
      },
      reminder: {
        title: 'Recordatorio:',
        label: 'antes de que comience mi objetivo.',
        intervalOptions: [
          { value: 30, text: '30 minutos' },
          { value: 60, text: '1 hora' },
          { value: 1440, text: '1 día' },
        ],
        madal_title: 'Recordatorios',
        modal_description: 'Tus ajustes de notificaciones para la Plantilla de objetivos se activarán en tu <strong>perfil</strong> cuando estableces un recordatorio para este objetivo.',
      },
      repeat: {
        title: 'Repetir:',
        label: 'Cada',
        frequency: [
          { value: 'DAY', text: 'día' },
          { value: 'WEEK', text: 'semana' },
        ],
        weekDays: [
          { value: 'MONDAY', text: 'L', label: 'Lunes' },
          { value: 'TUESDAY', text: 'M', label: 'Martes' },
          { value: 'WEDNESDAY', text: 'M', label: 'Miércoles' },
          { value: 'THURSDAY', text: 'J', label: 'Jueves' },
          { value: 'FRIDAY', text: 'V', label: 'Viernes' },
          { value: 'SATURDAY', text: 'S', label: 'Sábado' },
          { value: 'SUNDAY', text: 'D', label: 'Domingo' },
        ],
      },
      pinned: {
        title: 'Anclar a la página de inicio:',
      },
    },
    sections: {
      started: 'Objetivos iniciados',
      upcoming: 'Próximos objetivos',
      paused: 'Objetivos en pausa',
      completed: 'Objetivos completados',
    },
    goal_card: {
      motivational: [
        'Cada pequeño paso crea impulso. ¡Sigue adelante!',
        '¡Nos alegra verte de nuevo! ¿Cómo te fue?',
        '¡Bien hecho! ¡Sigue adelante!',
        'Cualquier paso adelante, por pequeño que sea, es un progreso. ¡Sigue adelante! ¡Tú puedes! ',
        '¡Puedes hacerlo! Sigue trabajando en tu objetivo.',
        'Encontrar lo que funciona requiere ensayo y error, así que no es un fracaso descubrir lo que no te funciona.',
        'No es cuánto haces, sino lo constante que eres al hacerlo lo que conduce a grandes cambios.',
        'Cada paso que das es un paso en la buena dirección. ¡Sigue adelante!',
      ],
      edit: '¿Quieres editar tu objetivo?',
      delete: '¡No hay problema! Confirma que quieres eliminar este objetivo.',
      pause_pt1: '¡No hay problema! Desactivaremos todos los recordatorios asociados con este objetivo y lo eliminaremos de tu página de inicio.',
      pause_pt2: 'Puedes continuar con este objetivo en cualquier momento desde tu <strong>Plantilla de objetivos</strong> en <strong>Herramientas</strong>.',
      complete: '¿Quieres marcar este objetivo como completado?',
      duplicate: '¡Genial! Crearemos un duplicado que podrás seguir modificando.',
      pin_first: 'El objetivo anclado a tu página de inicio permanecerá allí hasta que lo termines, ancles otro objetivo, lo pongas en pausa o lo desancles.',
      pin_replace: [
        'Si anclas este objetivo, se desanclará tu objetivo anterior.',
        'Siempre puedes encontrar tus objetivos en <strong>Herramientas</strong>.',
      ],
      unpin: 'Eliminaremos este objetivo de tu página de inicio. Puedes encontrarlo en cualquier momento en Herramientas.',
      content: {
        progress: 'Progrès :',
        slider_progress: 'Progrès',
        start: 'Iniciar',
        before: '{interval} antes',
        reminder: 'Recordatorio',
        repeat: 'Repetir',
        daily: 'Diario',
        weekly: 'Semanalmente cada',
      },
    },
    notifications: {
      errored: 'Se ha producido un error.',
    },
  },
  sleep_tracker: {
    header_title: 'Eficacia del sueño',
    header_subtitle: 'Registra nuevas entradas de sueño para monitorear su eficacia.',
    title: 'Eficacia del sueño',
    actions: {
      new_entry: 'Añadir una nueva entrada',
      calculate: 'Calcular',
    },
    fields: {
      date: {
        label: 'Fecha:',
        placeholder: 'Seleccionar la fecha',
      },
      in_bed: {
        label: 'Ir a la cama:',
        placeholder: 'Seleccionar la hora',
      },
      asleep: {
        label: 'Conciliar el sueño:',
        placeholder: 'Seleccionar la hora',
      },
      woke_up: {
        label: 'Despertarse:',
        placeholder: 'Seleccionar la hora',
      },
      out_bed: {
        label: 'Salir de la cama:',
        placeholder: 'Seleccionar la hora',
      },
    },
    sleep_card: {
      delete: 'No hay problema! Confirma que quieres eliminar esta entrada.',
      content: {
        date: 'Fecha:',
        in_bed: 'Ir a la cama:',
        asleep: 'Conciliar el sueño:',
        woke_up: 'Despertarse:',
        out_bed: 'Salir de la cama:',
        sleep_score: 'Puntuación de la eficacia del sueño:',
        target: 'Objetivo: 85%',
        time_asleep: 'Tiempo de sueño:',
        duration: '{hours} h {minutes} min',
      },
    },
    sections: {
      previous: 'Tus puntuaciones anteriores:',
    },
    notifications: {
      errored: 'Se ha producido un error.',
      invalid_time: 'Las horas seleccionadas deben ser posteriores a las seleccionadas anteriormente.',
    },
  },
  thought_balancer: {
    header_title: 'Equilibrador de pensamientos',
    actions: {
      save: 'Guardar pensamiento equilibrado',
    },
    tabs: {
      tool: {
        title: 'Equilibrar pensamientos',
        motivational: 'Toma el control de los pensamientos dañinos creando posiciones más equilibradas.',
        fields: {
          initial: {
            title: '¿Cuál es tu pensamiento?',
            subtitle: 'Si tu pensamiento es una pregunta, reescríbelo como una afirmación.',
            label: 'Pensamiento inicial',
            helperText: 'Por ejemplo, debería estar haciendo más.',
          },
          factsFor: {
            title: '¿Para qué sirven los hechos?',
            subtitle: 'Añade solo cosas que sean completamente ciertas.',
            label: 'Hechos de respaldo',
            helperText: 'Por ejemplo, tengo retraso en mi trabajo.',
          },
          factsAgainst: {
            title: '¿Cuáles son los hechos contradictorios?',
            subtitle: 'Piensa de forma creativa para establecer nuevas conexiones en tu cerebro.',
            label: 'Hechos contradictorios',
            helperText: 'Por ejemplo, mis colegas me dicen que soy una persona muy eficiente.',
          },
          balanced: {
            title: 'Crea tu posición equilibrada:',
            subtitle: 'Escribe una posición más equilibrada teniendo en cuenta los hechos de respaldo y los hechos contradictorios.',
            label: 'Pensamiento equilibrado',
            helperText: 'Por ejemplo, soy tan eficiente como me es posible. No puedo hacer más sin sacrificar mi salud.',
          },
        },
        buttons: {
          add_another: '+ añadir otro',
        },
      },
      history: {
        title: 'Historial de los pensamientos',
        motivational: 'Aquí tienes tus posiciones equilibradas:',
        empty: {
          title: 'Todavía no has ingresado ningún pensamiento.',
          subtitle: 'Selecciona la pestaña Equilibrar pensamientos para empezar.',
        },
      },
    },
    thought_card: {
      balanced: 'Pensamiento equilibrado',
      initial: 'Pensamiento inicial',
      facts_for: 'Hechos de respaldo',
      facts_against: 'Hechos contradictorios',
      edit: '¿Quieres editar tu pensamiento equilibrado?',
      delete: '¿Quieres realmente eliminar este pensamiento equilibrado?',
      see_more: 'Mostrar más',
      see_less: 'Mostrar menos',
    },
    notifications: {
      created: 'Pensamiento equilibrado creado',
      updated: 'Pensamiento equilibrado actualizado',
      deleted: 'Pensamiento equilibrado eliminado',
    },
  },
  mood_tracker: {
    header_title: 'Monitor de estado de ánimo',
    actions: {
      save_mood: 'Seleccionar',
      save_shift: 'Guardar cambio de humor',
    },
    tabs: {
      tool: {
        title: 'Monitorear estados de ánimo',
        motivational: 'Monitorea tus estados de ánimo y desencadenantes para identificar patrones comunes.',
        fields: {
          emotions: {
            title: 'Selecciona uno o más estados de ánimo para monitorear:',
            worried: 'Preocupación',
            sad: 'Tristeza',
            anxious: 'Ansiedad',
            stressed: 'Estrés',
            guilt: 'Culpa',
            angry: 'Ira',
            relaxed: 'Relajación',
            confident: 'Confianza',
            happy: 'Felicidad',
          },
          intensities: {
            title: 'Califica la intensidad de tu estado de ánimo:',
            mild: 'Leve',
            moderate: 'Moderado',
            intense: 'Intenso',
            reason: {
              title: 'Añade una descripción:',
              subtitle: '¿Qué estaba pasando? ¿Cómo reaccionaste?',
            },
          },
          date: {
            title: 'Añade cuándo ocurrió:',
            label: 'Fecha',
          },
          time: {
            label: 'Hora',
          },
        },
      },
      daily: {
        title: 'Diario',
        motivational: 'Monitorea tus estados de ánimo y desencadenantes para identificar patrones comunes.',
        empty: {
          title: 'Todavía no has ingresado ningún estado de ánimo.',
          subtitle: 'Selecciona la pestaña Monitorear estados de ánimo para empezar.',
        },
        chart: {
          label: {
            xAxis: 'Hora del día',
            yAxis: 'Intensidad de las emociones',
          },
        },
      },
      history: {
        title: 'Historial del estado de ánimo',
        motivational: 'Busca patrones como eventos, momentos del día o pensamientos comunes que provoquen estados de ánimo intensos.',
        empty: {
          title: 'Todavía no has ingresado ningún estado de ánimo.',
          subtitle: 'Selecciona la pestaña Monitorear estados de ánimo para empezar.',
        },
        chart: {
          label: {
            xAxis: 'Hora',
            yAxis: 'Intensidad de las emociones',
          },
        },
      },
    },
    mood_card: {
      edit: '¿Quieres editar tu estado de ánimo?',
      delete: '¿Quieres realmente eliminar este estado de ánimo?',
    },
    moods: [
      { value: 'worried', label: 'Preocupación' },
      { value: 'sad', label: 'Tristeza' },
      { value: 'anxious', label: 'Ansiedad' },
      { value: 'stressed', label: 'Estrés' },
      { value: 'guilt', label: 'Culpa' },
      { value: 'angry', label: 'Ira' },
      { value: 'relaxed', label: 'Relajación' },
      { value: 'confident', label: 'Confianza' },
      { value: 'happy', label: 'Felicidad' },
    ],
    notifications: {
      created: 'Estados de ánimo monitoreados',
      updated: 'Estados de ánimo actualizados',
      deleted: 'Estados de ánimo eliminados',
    },
  },
  workbook: {
    header_title: 'Mi diario',
    motivational: 'Estas son todas las entradas del diario de tu capacitación. Revísalas en cualquier momento para reflexionar y ampliar tus ideas.',
    program: {
      toolkit: 'Mi caja de herramientas de salud mental',
      stress: 'Plan de gestión del estrés laboral',
    },
    empty: {
      title: 'Aquí se mostrarán las entradas del diario de tu capacitación. Todavía no tienes ninguna.',
      subtitle: '',
    },
    entry_card: {
      edit: '¿Quieres editar esta entrada del diario?',
    },
    notifications: {
      updated: 'Entrada del diario actualizada',
    },
  },
  journal: {
    header_title: 'Mi diario',
    page_title: 'Diario',
    page_sub_title: 'Anotar tus pensamientos',
    page_instruction: 'Anotar tus perspectivas, aprendizajes y logros personale.',
    actions: {
      create: 'Nueva entrada',
    },
    fields: {
      title: {
        placeholder: 'Mi diario',
      },
      description: {
        placeholder: 'Añade tus pensamientos',
      },
    },
    sections: {
      title: 'Entradas de diario',
    },
    notifications: {
      errored: 'Se ha producido un error.',
    },
    sorts: [ 'Ordenar por más reciente', 'Ordenar por más antiguo' ],
    journal_card: {
      delete: '¡No hay problema! Confirma que quieres eliminar este diario.',
    },
  },
  note: {
    note: 'Nota',
  },
};
