var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "card-modal",
      class:
        ((_obj = {}),
        (_obj["modal-active " + _vm.activeModalView] = _vm.modalActive),
        _obj)
    },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-2", class: { completed: _vm.sleep.completed } },
        [
          _vm.activeModalView === "delete"
            ? _c("sleep-track-card-delete", {
                class:
                  ((_obj$1 = {}),
                  (_obj$1["content-active"] = _vm.modalActive),
                  _obj$1),
                attrs: { sleep: _vm.sleep, closeModal: _vm.closeModal },
                on: { action: _vm.onAction }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }