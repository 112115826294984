export default {
  header_title: 'My Profile',
  navigation: [
    {
      id: 'account',
      label: 'Account',
      path: 'profile-account',
    },
    {
      id: 'settings',
      label: 'Settings',
      path: 'profile-settings',
    },
  ],
  header: {
    fields: {
      name: {
        title: 'Name',
        question: 'What should we call you?',
        label: 'First name',
      },
    },
  },
  account: {
    title: 'Profile',
    joined: 'Joined',
    fields: {
      codeName: {
        title: 'Community Codename',
        question: 'Pick a community codename',
        label: 'Community codename',
        hint: 'Please click on the button below to generate a new codename',
        provider: {
          title: 'Randomize',
        },
      },
      yearOfBirth: {
        title: 'Year of Birth',
        question: 'May we know your year of birth?',
        label: 'Year of Birth',
      },
      profession: {
        title: 'Occupation',
        question: 'May we know your occupation?',
        label: 'Occupation',
      },
      memberCode: {
        title: 'Member ID',
        question: 'May we know your Member ID?',
        label: 'Member ID',
      },
      businessUnit: {
        title: 'Business Unit',
        question: 'May we know your business Unit?',
        label: 'Business Unit',
      },
      gender: {
        title: 'Gender',
        question: 'How would you like to be addressed?',
        label: 'Gender',
        custom_label: 'I self-describe as',
      },
      password: {
        title: 'Password',
        question: 'Change your password',
        current_password: 'Current password',
        new_password: 'New password',
        confirm_password: 'Confirm password',
      },
      email: {
        title: 'Email',
      },
      organization: {
        title: 'Organization',
      },
    },
    delete_account: {
      title: 'Delete Account',
      description: 'Please confirm account deletion. This action is irreversible and will permanently remove all your data associated with this account.',
      confirmation: {
        label: 'To confirm, type <strong><i>delete account</i></strong> in the text input field.',
        safe_word: 'delete account',
      },
      button: 'Delete Account',
      confirm_button: 'Delete Account',
    },
  },
  community: {
    title: 'Community settings',
    tagline: 'This will represent you in the community.',
  },
  settings: {
    title: 'Global Settings',
    fields: {
      language: {
        title: 'Language',
        question: 'What language do you use?',
        label: 'Language',
      },
      enable: {
        title: 'Enable translation',
      },
      timezone: {
        title: 'Timezone',
        question: 'Update your account\'s default timezone',
        label: 'Timezone',
      },
    },
  },
  home_settings: {
    title: 'Home Settings',
    tiles: {
      title: 'Tiles',
      action: 'Visible',
      community_notification: 'Community notifications',
      my_progress: 'My progress',
    },
  },
  communication: {
    title: 'Notification Settings',
    email: 'Email',
    app: 'App',
    comments: {
      title: 'Comments',
      reactions: 'Reactions',
      replies: 'Replies',
    },
    progress: {
      title: 'Progress',
      session: 'Session',
      checkup: 'Check-Up',
      goal_reminder: 'Goal Reminders',
      webinar_reminder: 'Webinar Reminders',
    },
    completion: {
      title: 'Completion',
      session: 'Session',
      exercise: 'Exercise',
    },
    product_updates: {
      updates: 'Product updates',
    },
    goal_reminder_modal: {
      title: 'Heads up.',
      description: 'Turning goal reminders off will pause notifications for all goals you\'ve set.<br><br> To turn off reminders for a specific goal, you can edit it in the <strong>Goal Setter</strong> under <strong>Tools.</strong>',
    },
    warning_push_disabled: {
      title: 'Heads up.',
      description: 'Your device is not set up to receive push notifications. You can enable them in your phone\'s settings.',
    },
  },
  feedback_block: {
    title: 'Feedback',
    greeting: 'How are we doing?',
    buttons: {
      write: 'Send us feedback',
    },
  },
  misc: {
    no_disclosure: 'I prefer not to disclose',
    self_describe: 'I self-describe as {value}',
    no_match: 'No matches were found',
  },
  notifications: {
    updated: 'Profile updated',
    password_changed: 'Password changed',
    password_not_changed: 'Your current password is incorrect, please try again.',
  },
  feedback: {
    mailto: 'mailto:members@starlingminds.com?subject=Feedback%20and%20questions',
  },
  webinars: {
    title: 'Webinar Settings',
    header_webinars: 'Webinars',
    header_registered: 'Registered',
    no_items: 'No upcoming webinars. Stay up to date with new webinars by keeping product updates on in your notification settings.',
  },
};
