import { render, staticRenderFns } from "./locale-switcher.vue?vue&type=template&id=9b3761a2&"
import script from "./locale-switcher.vue?vue&type=script&lang=js&"
export * from "./locale-switcher.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VIcon,VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2439010145/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9b3761a2')) {
      api.createRecord('9b3761a2', component.options)
    } else {
      api.reload('9b3761a2', component.options)
    }
    module.hot.accept("./locale-switcher.vue?vue&type=template&id=9b3761a2&", function () {
      api.rerender('9b3761a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/localeSwitcher/locale-switcher.vue"
export default component.exports