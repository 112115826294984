var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "sleep-track-form pa-0",
      attrs: { elevation: 0, light: false }
    },
    [
      _vm.currentStep === 0
        ? [
            _c("sleep-track-form-calculate", {
              attrs: {
                value: _vm.internalValue,
                cancellable: _vm.cancellable,
                loading: _vm.loading
              },
              on: { save: _vm.onUpdate, cancel: _vm.onCancel }
            })
          ]
        : [
            _c("sleep-track-form-score", {
              attrs: { value: _vm.internalValue },
              on: { edit: _vm.onEdit, update: _vm.onUpdate, save: _vm.onSave }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }