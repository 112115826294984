import Vuetify, {
  VApp,
  VBottomNav,
  VBtn,
  VCard,
  VChip,
  VDatePicker,
  VDialog,
  VExpansionPanel,
  VIcon,
  VList,
  VMenu,
  VProgressCircular,
  VSlider,
  VSnackbar,
  VSpacer,
  VTabs,
  VTextField,
  VTimePicker,
} from 'vuetify/lib';

import HomeFilledIcon from '@/views/icons/home-filled-icon';
import HomeIcon from '@/views/icons/home-icon';
import ToolsIcon from '@/views/icons/tools-icon';
import GuidedTherapyIcon from '@/views/icons/guided-therapy-icon';
import ProfileIcon from '@/views/icons/profile-icon';
import CommunityIcon from '@/views/icons/community-icon';
import EyeOpenedIcon from '@/views/icons/eye-opened-icon';
import EyeClosedIcon from '@/views/icons/eye-closed-icon';
import VideosIcon from '@/views/icons/videos-icon';
import LogoutIcon from '@/views/icons/logout-icon';
import RandomizeIcon from '@/views/icons/randomize-icon';
import ThoughtBalancerIcon from '@/views/icons/thought-balancer-icon';
import MoodTrackerIcon from '@/views/icons/mood-tracker-icon';
import GoalSetterIcon from '@/views/icons/goal-setter-icon';
import WorkbookIcon from '@/views/icons/workbook-icon';
import MyProgressIcon from '@/views/icons/my-progress-icon';
import TherapyIcon from '@/views/icons/therapy-icon';
import CheckUpIcon from '@/views/icons/checkup-icon';
import ExercisesIcon from '@/views/icons/exercises-icon';
import WebinarIcon from '@/views/icons/webinar-icon';
import ThanksIcon from '@/views/icons/thanks-icon';
import CareIcon from '@/views/icons/care-icon';
import FavoriteIcon from '@/views/icons/favorite-icon.vue';
import AudioIcon from '@/views/icons/audio-icon.vue';
import GoogleIcon from '@/views/icons/google-icon.vue';
import FacebookIcon from '@/views/icons/facebook-icon.vue';
import AppleIcon from '@/views/icons/apple-icon.vue';
import JournalIcon from '@/views/icons/journal-icon';
import CommentNewReplyIcon from '@/views/icons/comment-new-reply-icon';
import CommentNewReplyDraftIcon from '@/views/icons/comment-new-reply-draft-icon';
import SleepTrackerIcon from '@/views/icons/sleep-tracker-icon';

export default (Vue) => {
  Vue.use(Vuetify, {
    components: {
      VApp,
      VBtn,
      VBottomNav,
      VSnackbar,
      VCard,
      VDatePicker,
      VDialog,
      VIcon,
      VList,
      VMenu,
      VProgressCircular,
      VSlider,
      VSpacer,
      VTabs,
      VTextField,
      VTimePicker,
      VChip,
      VExpansionPanel,
    },
    theme: {
      primary: '#19aef6',
      secondary: '#23cdfd',
      accent: '#22a71b',
      error: '#ff4850',
      success: '#08eacf',
      warning: '#ffdc75',
      info: '#4f4f4f',
    },
    iconfont: 'mdi',
    icons: {
      starling_home_filled: {
        component: HomeFilledIcon,
      },
      starling_home: {
        component: HomeIcon,
      },
      starling_tools: {
        component: ToolsIcon,
      },
      starling_guided_therapy: {
        component: GuidedTherapyIcon,
      },
      starling_eye_closed: {
        component: EyeClosedIcon,
      },
      starling_eye_opened: {
        component: EyeOpenedIcon,
      },
      starling_videos: {
        component: VideosIcon,
      },
      starling_profile: {
        component: ProfileIcon,
      },
      starling_favorite: {
        component: FavoriteIcon,
      },
      starling_audio: {
        component: AudioIcon,
      },
      starling_community: {
        component: CommunityIcon,
      },
      starling_logout: {
        component: LogoutIcon,
      },
      starling_randomize: {
        component: RandomizeIcon,
      },
      starling_thought_balancer: {
        component: ThoughtBalancerIcon,
      },
      starling_mood_tracker: {
        component: MoodTrackerIcon,
      },
      starling_goal_setter: {
        component: GoalSetterIcon,
      },
      starling_assessment: {
        component: MyProgressIcon,
      },
      starling_workbook: {
        component: WorkbookIcon,
      },
      starling_therapy: {
        component: TherapyIcon,
      },
      starling_checkup: {
        component: CheckUpIcon,
      },
      starling_exercises: {
        component: ExercisesIcon,
      },
      starling_webinar: {
        component: WebinarIcon,
      },
      starling_thanks: {
        component: ThanksIcon,
      },
      starling_care: {
        component: CareIcon,
      },
      starling_social_facebook: {
        component: FacebookIcon,
      },
      starling_social_apple: {
        component: AppleIcon,
      },
      starling_social_google: {
        component: GoogleIcon,
      },
      starling_journal: {
        component: JournalIcon,
      },
      starling_comment_new_reply: {
        component: CommentNewReplyIcon,
      },
      starling_comment_new_reply_draft: {
        component: CommentNewReplyDraftIcon,
      },
      starling_sleep_tracker: {
        component: SleepTrackerIcon,
      },
    },
  });
};
