var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("custom-confirmation-modal", {
    attrs: {
      display: "",
      title: _vm.$t(
        "app.products.element.webinar_registration.notifications.title"
      ),
      description: _vm.$t(
        "app.products.element.webinar_registration.notifications.warning"
      ),
      primaryConfirmLabel: _vm.$t("common.actions.continue"),
      secondaryCancelLabel: _vm.$t("common.actions.cancel")
    },
    on: { "cancel-event": _vm.onCancel, "confirm-event": _vm.onConfirm }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }