<template>
<v-layout row wrap pt-3 gap-xs-3 class="tools-card">
  <v-flex xs12>
    <h2 class="starling-h2">{{ $t('app.tools.title') }}</h2>
  </v-flex>
  <v-flex xs12 v-for="tool in filteredTools" :key="tool.id+'_'+key">
    <v-hover>
      <v-card slot-scope="{ hover }" class="tool-card cursor-pointer radius-15" :class="`elevation-${hover ? 12 : 3}`" @click="goTo(tool)">
        <v-card-text class="pa-2">
          <v-layout row justify-center align-center gap-xs-3>
            <v-flex xs4>
              <v-responsive cover class="text-center" v-if="tool.icon">
                <v-icon class="primary--text">{{tool.icon}}</v-icon>
              </v-responsive>

              <component v-else-if="tool.iconComponent && loadedProps[tool.id]"
                          :style="'cursor: pointer;!important'"
                          :is="tool.iconComponent.name"
                          v-bind="loadedProps[tool.id]"/>

              <v-responsive cover class="text-center" v-else>
                <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
              </v-responsive>
            </v-flex>

            <v-flex xs8>
              <p class="starling-body StarlingDarkblue--text">{{ tool.subtitle }}</p>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions class="pa-2">
          <p class="starling-body StarlingPrimary1--text">{{ tool.title }}</p>
        </v-card-actions>
      </v-card>
    </v-hover>
  </v-flex>
</v-layout>
</template>

<script>
import CheckupResultsRadarchart from '@/views/home/components/checkup-results-radarchart';

export default {
  name: 'toolsView',
  components: { CheckupResultsRadarchart },
  data() {
    return {
      key: 0,
      loadedProps: {},
    };
  },
  computed: {
    assessmentId() {
      return this.$store.getters.user && this.$store.getters.user.assessmentId;
    },
    recommendedTools() {
      return this.$store.getters['tools/tools'];
    },
    tools() {
      const allTools = this.$t('app.tools.content');
      return this.recommendedTools.map(t => allTools[t]).filter(tool => !!tool);
    },
    filteredTools() {
      return this.tools.filter(tool => !tool.iconComponent || !tool.iconComponent.isValid || tool.iconComponent.isValid(this.loadedProps[tool.id]));
    },
  },
  mounted() {
    this.tools.filter(tool => tool.iconComponent && tool.iconComponent.propsLoader).forEach(tool => {
      tool.iconComponent.propsLoader().then(res => {
        res.showDrilldown = false;
        res.canvasWidth = '100%';
        res.showLabels = false;
        res.showAnimation = false;
        this.$set(this.loadedProps, tool.id, res);
        this.key++;
        this.$forceUpdate();
      });
    });
  },
  methods: {
    goTo(tool) {
      this.$router.push(tool.route);
    },
  },
};
</script>

<style>
.tool-card .v-card__actions {
  border-top: 1px solid lightgray;
}
</style>
