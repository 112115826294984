












import Vue from 'vue';
import CustomConfirmationModal from '@/views/components/utilities/custom-confirmation-modal.vue';

export default Vue.extend({
  name: 'webinar-notifications-warning',
  components: { CustomConfirmationModal },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onConfirm() {
      this.$emit('confirm');
    },
  },
});
