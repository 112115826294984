var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-text-field", {
        attrs: {
          readonly: "",
          outline: "",
          "hide-details": "",
          "prepend-icon": "mdi-calendar-blank",
          rules: _vm.required ? _vm.rules.required : [],
          placeholder: _vm.placeholder
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.openDialog($event)
          },
          "click:prepend": function($event) {
            $event.stopPropagation()
            return _vm.openDialog($event)
          }
        },
        model: {
          value: _vm.formattedDate,
          callback: function($$v) {
            _vm.formattedDate = $$v
          },
          expression: "formattedDate"
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "radius-15",
            width: "290px",
            persistent: "",
            lazy: "",
            "full-width": ""
          },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c(
            "v-date-picker",
            {
              attrs: {
                color: "primary",
                scrollable: "",
                locale: _vm.currentLanguage,
                min: _vm.minDate,
                max: _vm.maxDate
              },
              model: {
                value: _vm.internalValue,
                callback: function($$v) {
                  _vm.internalValue = $$v
                },
                expression: "internalValue"
              }
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { flat: "" }, on: { click: _vm.closeDialog } },
                [_vm._v(_vm._s(_vm.$t("common.actions.cancel")))]
              ),
              _c("primary-button", { on: { click: _vm.save } }, [
                _vm._v(_vm._s(_vm.$t("common.actions.save")))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }