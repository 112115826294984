var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "sleep-tracker-icon",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 95 95"
      }
    },
    [
      _c("path", {
        staticClass: "icon-colorized",
        attrs: {
          d:
            "M54.92 25c4.48 0 8.28 1.562 11.4 4.686C69.44 32.81 71 36.615 71 41.1h-4.594c0-1.533-.278-2.961-.833-4.284a12.01 12.01 0 0 0-2.268-3.536l-3.159 5.347a164.21 164.21 0 0 0-1.235 2.013c-.44.728-.909 1.36-1.407 1.898-.536.575-1.206.862-2.01.862-.804 0-1.493-.268-2.067-.805-.574-.537-.861-1.207-.861-2.012 0-.805.287-1.495.861-2.07a8.844 8.844 0 0 1 1.924-1.438 53.004 53.004 0 0 0 2.038-1.208l5.341-3.162a11.993 11.993 0 0 0-3.531-2.271c-1.321-.556-2.747-.834-4.279-.834V25Zm-6.719 46c-3.216 0-6.231-.613-9.045-1.84s-5.264-2.885-7.35-4.974c-2.087-2.089-3.743-4.542-4.968-7.36C25.613 54.01 25 50.99 25 47.77c0-5.597 1.78-10.532 5.34-14.806 3.561-4.274 8.098-6.929 13.611-7.964-.689 3.795-.478 7.504.632 11.126 1.11 3.623 3.025 6.795 5.743 9.517 2.718 2.721 5.886 4.638 9.504 5.75 3.618 1.111 7.322 1.322 11.113.632-.996 5.52-3.638 10.063-7.925 13.628C58.73 69.216 53.79 71 48.2 71Zm0-4.6c3.37 0 6.49-.843 9.36-2.53 2.872-1.687 5.131-4.006 6.777-6.957a28.468 28.468 0 0 1-9.36-2.502 27.297 27.297 0 0 1-7.926-5.549 27.798 27.798 0 0 1-5.57-7.934 27.176 27.176 0 0 1-2.47-9.373c-2.947 1.648-5.254 3.92-6.92 6.814-1.665 2.894-2.498 6.028-2.498 9.401 0 5.175 1.81 9.574 5.427 13.196 3.618 3.623 8.011 5.434 13.18 5.434Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }