









import Vue from 'vue';
import SleepTrackCard from '@/views/tools/sleep/components/sleep-track-card.vue';
import SleepTrackForm from '@/views/tools/sleep/components/track/sleep-track-form.vue';
import { ITherapyContentSleepTrack } from '@/scripts/store/modules/therapy/types';
import { ISleepTracker } from '@/scripts/store/modules/tools/sleep/types';

export default Vue.extend({
  name: 'element-sleep-track',
  components: {
    SleepTrackCard,
    SleepTrackForm,
  },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentSleepTrack,
      required: true,
    },
  },
  data() {
    return {
      loadedSleepTrack: false,
      loading: false,
      linkedSleepTrack: {} as ISleepTracker,
      editing: false,
    };
  },
  mounted() {
    this.$log.debug('SleepTrack element', this.therapyContent);
    this.$emit('completeElement', { valid: false });
    if (this.therapyContent.userSleepTrackId) {
      this.$store.dispatch('sleep/getSleepTrack', this.therapyContent.userSleepTrackId).then(res => {
        if (!res || res.status >= 300) throw new Error();
        this.linkedSleepTrack = res;
        this.resetConfig();
        this.$emit('completeElement', { valid: true });
      }).catch(() => {
        this.resetConfig();
      }).finally(
        () => {
          this.loadedSleepTrack = true;
        },
      );
    } else {
      this.resetConfig();
      this.loadedSleepTrack = true;
    }
  },
  methods: {
    resetConfig() {
      if (!this.linkedSleepTrack.uen) {
        this.linkedSleepTrack.uen = this.therapyContent.uen;
      }
    },
    onEdit() {
      this.editing = true;
      this.$emit('completeElement', { valid: false });
    },
    onCancel() {
      this.editing = false;
    },
    onUpdate(sleeptrack: ISleepTracker) {
      this.linkedSleepTrack = sleeptrack || {};
      // eslint-disable-next-line vue/no-mutating-props
      this.therapyContent.userSleepTrackId = sleeptrack?.id ? parseInt(sleeptrack.id) : undefined;
      this.resetConfig();
      this.editing = false;
      this.$emit('completeElement', { valid: !!sleeptrack });
    },
  },
});
