








































import Vue from 'vue';
import CustomConfirmationModal from '@/views/components/utilities/custom-confirmation-modal.vue';
import { ProfileCommunication } from '@/views/profile/partials/profile-communication.vue';

export default Vue.extend({
  name: 'webinar-notifications-popup',
  components: { CustomConfirmationModal },
  data() {
    return { show: false };
  },
  computed: {
    isNativePlatform(): boolean {
      return this.$store.getters.isNativePlatform;
    },
    user(): any {
      return this.$store.getters.user;
    },
    emailSettings: {
      get(): ProfileCommunication {
        return this.user.notifications.find((notification: ProfileCommunication) => notification.type === 'EMAIL');
      },
      set(newValue: ProfileCommunication): void {
        this.user.notifications = this.user.notifications.map((notification: ProfileCommunication) => {
          if (notification.type === 'EMAIL') {
            return newValue;
          }
          return notification;
        });
      },
    },
    pushSettings: {
      get(): ProfileCommunication {
        return this.user.notifications.find((notification: ProfileCommunication) => notification.type === 'PUSH');
      },
      set(newValue: ProfileCommunication): void {
        this.user.notifications = this.user.notifications.map((notification: ProfileCommunication) => {
          if (notification.type === 'PUSH') {
            return newValue;
          }
          return notification;
        });
      },
    },
  },
  mounted() {
    if (!this.emailSettings.webinarReminder || !this.pushSettings.webinarReminder) {
      this.emailSettings.webinarReminder = true;
      this.pushSettings.webinarReminder = true;
      this.show = true;
    } else {
      this.$emit('confirm');
    }
  },
  methods: {
    onCancel() {
      this.$store.dispatch('getUser').finally(() => {
        this.$emit('cancel');
      });
    },
    onConfirm() {
      this.$store.dispatch('editUser', {}).then(() => {
        this.$emit('confirm');
      });
    },
  },
});
