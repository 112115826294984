








import Vue from 'vue';
import SleepWindowInputWrapper from '@/views/components/utilities/sleep-window-input-wrapper.vue';
import { ITherapyContentSleepWindow } from '@/scripts/store/modules/therapy/types';

export default Vue.extend({
  name: 'element-sleep-track',
  components: {
    SleepWindowInputWrapper,
  },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentSleepWindow,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.$log.debug('SleepWindow element', this.therapyContent);
    this.$emit('completeElement', { valid: false });
  },
  methods: {
    onCompleted() {
      this.$log.debug('SleepWindow element completed !');
      this.$emit('completeElement', { valid: true });
    },
  },
});
