var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "mb-3" },
    [
      _c(
        "v-list",
        { staticClass: "home-hidden-widget-list" },
        [
          _c(
            "v-list-group",
            {
              attrs: { "prepend-icon": null, "append-icon": null },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function() {
                    return [
                      _c(
                        "v-list-tile",
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c(
                                "v-list-tile-title",
                                {
                                  staticClass:
                                    "starling-h5 StarlingPrimary1--text",
                                  staticStyle: { height: "35px" }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.state.hidden")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "text-center clickable",
                              staticStyle: {
                                display: "inline-block",
                                position: "absolute",
                                right: "-7px",
                                top: "calc(50% - 20px)",
                                width: "40px",
                                height: "40px"
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "StarlingPrimary1--text",
                                  attrs: { size: "40" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.opened ? "expand_less" : "expand_more"
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "StarlingPrimary1--text pa-0 ma-0",
                                  staticStyle: {
                                    "font-size": "10px",
                                    "line-height": "10px",
                                    bottom: "6px",
                                    position: "relative",
                                    opacity: "0"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("common.actions.show")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.opened,
                callback: function($$v) {
                  _vm.opened = $$v
                },
                expression: "opened"
              }
            },
            [
              _vm.hiddenCommentReplies && this.hasCommentReplies
                ? _c(
                    "v-list-tile",
                    [
                      _c(
                        "v-list-tile-content",
                        [
                          _c(
                            "v-list-tile-title",
                            { staticClass: "StarlingPrimary1--text" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "app.profile.home_settings.tiles.community_notification"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "text-center clickable",
                          staticStyle: {
                            display: "inline-block",
                            position: "absolute",
                            right: "0",
                            top: "calc(50% - 24.5px)"
                          },
                          on: {
                            click: function($event) {
                              return _vm.onShowFeature("commentReplies")
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "StarlingPrimary1--text" },
                            [_vm._v("visibility")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "StarlingPrimary1--text pa-0 ma-0",
                              staticStyle: {
                                "font-size": "10px",
                                "line-height": "10px",
                                bottom: "6px",
                                position: "relative"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.actions.show")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hiddenCheckupReminder
                ? _c(
                    "v-list-tile",
                    [
                      _c(
                        "v-list-tile-content",
                        [
                          _c(
                            "v-list-tile-title",
                            { staticClass: "StarlingPrimary1--text" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "app.profile.home_settings.tiles.my_progress"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "text-center clickable",
                          staticStyle: {
                            display: "inline-block",
                            position: "absolute",
                            right: "0",
                            top: "calc(50% - 24.5px)"
                          },
                          on: {
                            click: function($event) {
                              return _vm.onShowFeature("checkUpReminder")
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "StarlingPrimary1--text" },
                            [_vm._v("visibility")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "StarlingPrimary1--text pa-0 ma-0",
                              staticStyle: {
                                "font-size": "10px",
                                "line-height": "10px",
                                bottom: "6px",
                                position: "relative"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.actions.show")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }