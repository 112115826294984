var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadedSleep
    ? _c(
        "v-layout",
        {
          staticClass: "edit-sleep-track",
          attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" }
        },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("h1", { staticClass: "starling-h1" }, [
              _vm._v(_vm._s(_vm.$t("app.tools.sleep_tracker.header_title")))
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("p", { staticClass: "starling-explanatory" }, [
              _vm._v(_vm._s(_vm.$t("app.tools.sleep_tracker.header_subtitle")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", lg9: "", "text-left": "" } },
            [
              _c("sleep-track-form", {
                attrs: { value: _vm.loadedSleep, cancellable: "" },
                on: {
                  update: _vm.goToSleepTrackList,
                  cancel: _vm.goToSleepTrackList
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }