












import Vue from 'vue';
import SleepTrackFormCalculate from '@/views/tools/sleep/components/track/sleep-track-form-calculate.vue';
import SleepTrackFormScore from '@/views/tools/sleep/components/track/sleep-track-form-score.vue';
import { ISleepTracker } from '@/scripts/store/modules/tools/sleep/types';

export default Vue.extend({
  name: 'sleep-track-form',
  components: { SleepTrackFormCalculate, SleepTrackFormScore },
  props: {
    value: {
      type: Object as () => ISleepTracker,
      required: true,
    },
    cancellable: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      currentStep: 0,
      internalValue: {} as ISleepTracker,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue === oldValue) return;
        this.internalValue = newValue;
      },
    },
  },
  methods: {
    onUpdate(value: ISleepTracker) {
      this.$log.debug('Submitting sleep', value);
      this.loading = true;
      this.internalValue = value;

      const storeAction = this.internalValue.id
        ? this.$store.dispatch('sleep/editSleepTrack', this.internalValue)
        : this.$store.dispatch('sleep/createSleepTrack', this.internalValue);

      storeAction.then(res => {
        if (res.status && res.status > 300) throw Error();
        this.currentStep = 1;
        this.internalValue = res;
      }).catch((err) => {
        this.$log.debug('Error submitting sleep', err);
        this.$store.commit('setNotification', { text: this.$t('app.tools.sleep_tracker.notifications.errored') });
      }).finally(() => {
        this.loading = false;
      });
    },
    onEdit() {
      this.currentStep = 0;
    },
    onSave() {
      this.$emit('update', this.internalValue);
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
});
