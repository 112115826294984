import { render, staticRenderFns } from "./landing-central-card.vue?vue&type=template&id=1ab2c370&scoped=true&"
import script from "./landing-central-card.vue?vue&type=script&lang=js&"
export * from "./landing-central-card.vue?vue&type=script&lang=js&"
import style0 from "./landing-central-card.vue?vue&type=style&index=0&id=1ab2c370&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ab2c370",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VFlex,VFooter,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2439010145/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1ab2c370')) {
      api.createRecord('1ab2c370', component.options)
    } else {
      api.reload('1ab2c370', component.options)
    }
    module.hot.accept("./landing-central-card.vue?vue&type=template&id=1ab2c370&scoped=true&", function () {
      api.rerender('1ab2c370', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/landing/landing-central-card.vue"
export default component.exports