var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("custom-confirmation-modal", {
    attrs: {
      display: _vm.show,
      title: _vm.$t(
        "app.products.element.webinar_registration.notifications.title"
      ),
      description: _vm.$t(
        "app.products.element.webinar_registration.notifications.description"
      ),
      primaryConfirmLabel: _vm.$t("common.actions.continue"),
      secondaryCancelLabel: _vm.$t("common.actions.cancel")
    },
    on: { "cancel-event": _vm.onCancel, "confirm-event": _vm.onConfirm },
    scopedSlots: _vm._u([
      {
        key: "afterDescription",
        fn: function() {
          return [
            _c(
              "v-layout",
              {
                staticClass: "mt-2",
                attrs: { row: "", wrap: "", "align-center": "" }
              },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "v-layout",
                      { staticClass: "my-2", attrs: { row: "" } },
                      [
                        _c("v-flex", { attrs: { xs8: "", sm9: "" } }, [
                          _c(
                            "p",
                            { staticClass: "starling-text text--primary" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("app.profile.communication.email")
                                )
                              )
                            ]
                          )
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { xs4: "", sm3: "" } },
                          [
                            _c("v-switch", {
                              staticClass: "ma-0 justify-center",
                              attrs: { "hide-details": "" },
                              model: {
                                value: _vm.emailSettings.webinarReminder,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.emailSettings,
                                    "webinarReminder",
                                    $$v
                                  )
                                },
                                expression: "emailSettings.webinarReminder"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-layout",
                      { staticClass: "my-2", attrs: { row: "" } },
                      [
                        _c("v-flex", { attrs: { xs8: "", sm9: "" } }, [
                          _c(
                            "p",
                            { staticClass: "starling-text text--primary" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("app.profile.communication.app"))
                              )
                            ]
                          )
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { xs4: "", sm3: "" } },
                          [
                            _c("v-switch", {
                              staticClass: "ma-0 justify-center",
                              attrs: { "hide-details": "" },
                              model: {
                                value: _vm.pushSettings.webinarReminder,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.pushSettings,
                                    "webinarReminder",
                                    $$v
                                  )
                                },
                                expression: "pushSettings.webinarReminder"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }