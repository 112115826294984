import { render, staticRenderFns } from "./first-checkup-block.vue?vue&type=template&id=01ef88ec&"
import script from "./first-checkup-block.vue?vue&type=script&lang=ts&"
export * from "./first-checkup-block.vue?vue&type=script&lang=ts&"
import style0 from "./first-checkup-block.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VFlex,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2439010145/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01ef88ec')) {
      api.createRecord('01ef88ec', component.options)
    } else {
      api.reload('01ef88ec', component.options)
    }
    module.hot.accept("./first-checkup-block.vue?vue&type=template&id=01ef88ec&", function () {
      api.rerender('01ef88ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/navigation/first-checkup-block.vue"
export default component.exports