<template>
  <v-btn v-bind="$attrs" @click="$emit('click', $event)" color="primary--gradient" class="starling-button-primary font-weight-bold white--text ma-0" round>
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'primary-button',
};
</script>

<style scoped>
.starling-button-primary:hover:before{
  background-color:var(--button-hover);
  opacity:var(--button-hover-opacity);
  transition: .3s cubic-bezier(.25,.8,.5,1);
}

.starling-button-primary,
.starling-button-primary::after {
  opacity: 1 !important;
  border-radius: var(--button-radius);
}
</style>
