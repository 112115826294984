import { render, staticRenderFns } from "./Feedback.vue?vue&type=template&id=27aaf3e5&"
import script from "./Feedback.vue?vue&type=script&lang=js&"
export * from "./Feedback.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCard,VCardActions,VContainer,VFlex,VForm,VLayout,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2439010145/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('27aaf3e5')) {
      api.createRecord('27aaf3e5', component.options)
    } else {
      api.reload('27aaf3e5', component.options)
    }
    module.hot.accept("./Feedback.vue?vue&type=template&id=27aaf3e5&", function () {
      api.rerender('27aaf3e5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/profile/Feedback.vue"
export default component.exports