import { render, staticRenderFns } from "./goal-form.vue?vue&type=template&id=603f0b42&"
import script from "./goal-form.vue?vue&type=script&lang=ts&"
export * from "./goal-form.vue?vue&type=script&lang=ts&"
import style0 from "./goal-form.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VBtnToggle,VCard,VDatePicker,VDialog,VFlex,VForm,VIcon,VLayout,VSelect,VSpacer,VSwitch,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2439010145/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('603f0b42')) {
      api.createRecord('603f0b42', component.options)
    } else {
      api.reload('603f0b42', component.options)
    }
    module.hot.accept("./goal-form.vue?vue&type=template&id=603f0b42&", function () {
      api.rerender('603f0b42', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/tools/goals/components/goal-form.vue"
export default component.exports