































































































































































import Vue, { PropType } from 'vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import TimePicker from '@/views/components/utilities/time-picker.vue';
import moment, { Moment } from 'moment';
import { ISleepWindow } from '@/scripts/store/modules/tools/sleep/types';
import store from '@/scripts/store';

export default Vue.extend({
  name: 'sleep-window-input',
  components: { TimePicker, PrimaryButton },
  props: {
    value: Object as PropType<ISleepWindow>,
  },
  data() {
    return {
      innerValue: {
        desiredHours: this.value && this.value.desiredHours ? this.value.desiredHours : '9' as string,
        desiredMinutes: this.value && this.value.desiredMinutes ? this.value.desiredMinutes : '00' as string,
        desiredWakeTime: this.value && this.value.desiredWakeTime ? this.value.desiredWakeTime : '07:00' as string,
        computedBedTime: this.value && this.value.computedBedTime ? this.value.computedBedTime : undefined as undefined | string,
      } as ISleepWindow,
      wakeTimeDialog: false,
      form: {
        hours: '9',
        minutes: '00',
        desiredWakeTime: '07:00 am',
      },
      isDirty: false,
    };
  },
  computed: {
    currentLocale() {
      return store.getters.getCurrentLocale;
    },
    timeFormat() {
      return store.getters.getHourFormat;
    },
    isComputed(): boolean {
      return this.innerValue && !!this.innerValue.computedBedTime && !!this.innerValue.desiredWakeTime;
    },
    formattedBedTime(): string {
      return this.innerValue && this.innerValue.computedBedTime ? moment(this.innerValue.computedBedTime, 'HH:mm').format('LT') : '';
    },
    formattedWakeTime(): string {
      return this.innerValue && this.innerValue.desiredWakeTime ? moment(this.innerValue.desiredWakeTime, 'HH:mm').format('LT') : '';
    },
  },
  watch: {
    'innerValue.desiredHours'() {
      this.isDirty = true;
    },
    'innerValue.desiredMinutes'() {
      this.isDirty = true;
    },
    'innerValue.desiredWakeTime'() {
      this.isDirty = true;
    },
  },
  mounted() {
  },
  methods: {
    onCalculate() {
      const wakeTime: Moment = moment(this.innerValue.desiredWakeTime, 'HH:mm');

      const bedTime: Moment = wakeTime.clone().subtract({
        hours: Number(this.innerValue.desiredHours),
        minutes: Number(this.innerValue.desiredMinutes),
      });

      this.innerValue.desiredWakeTime = wakeTime.format('HH:mm');
      this.innerValue.computedBedTime = bedTime.format('HH:mm');
      this.isDirty = false;

      this.$emit('input', this.innerValue);
    },
  },
});
