
































import Vue from 'vue';
import { ISleepTracker } from '@/scripts/store/modules/tools/sleep/types';
import moment from 'moment';

export default Vue.extend({
  name: 'sleep-track-card-header',
  props: {
    sleep: {
      type: Object as () => ISleepTracker,
      required: true,
    },
    toggleModal: {
      type: Function,
      required: true,
    },
    activeModalView: {
      type: String,
      required: false,
    },
  },
  computed: {
    dateFormatted(): string {
      return moment(this.sleep.refDate).format('LL');
    },
  },
});
