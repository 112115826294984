




























import Vue from 'vue';
import { ISleepTracker } from '@/scripts/store/modules/tools/sleep/types';
import SleepTrackCardHeader from '@/views/tools/sleep/components/sleep-track-card-header.vue';
import SleepTrackCardModal from '@/views/tools/sleep/components/card-modal/sleep-track-card-modal.vue';
import SleepTrackCardContent from '@/views/tools/sleep/components/sleep-track-card-content.vue';

export default Vue.extend({
  name: 'sleepTrackCard',
  components: {
    SleepTrackCardHeader,
    SleepTrackCardModal,
    SleepTrackCardContent,
  },
  props: {
    sleep: {
      type: Object as () => ISleepTracker,
      required: true,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeModalView: undefined as string | undefined,
      modalActive: false,
      panel: null as number | null,
    };
  },
  methods: {
    togglePanel() {
      if (this.panel === 0) {
        this.panel = null;
        this.closeModal();
      } else {
        this.panel = 0;
      }
    },
    toggleModal(viewName: string) {
      if (viewName === 'edit') {
        this.onEdit();
        return;
      }
      this.panel = 0;
      this.activeModalView = viewName;
      this.modalActive = true;
    },
    closeModal() {
      this.modalActive = false;
      this.activeModalView = undefined;
    },
    onDelete() {
      this.$store.dispatch('sleep/deleteSleepTrack', this.sleep.id).then(() => {
        this.closeModal();
        this.$emit('update', undefined);
      }, () => {
        this.$store.commit('setNotification', { text: this.$t('app.tools.sleep_tracker.notifications.errored') });
      });
    },
    onEdit() {
      this.closeModal();
      this.$emit('edit', this.sleep);
    },
    onUpdate(sleep: ISleepTracker) {
      this.$store.dispatch('sleep/editSleepTrack', sleep)
        .then(res => {
          this.$emit('update', res);
        }, () => {
          this.$store.commit('setNotification', { text: this.$t('app.tools.sleep_tracker.notifications.errored') });
        });
    },
  },
});
