import { BoilerplateView, TopNavDesktopView, TopNavMobileView } from '../common';
import moods from './moods';
import thoughts from './thoughts';
import goals from './goals';
import journals from './journals';
import sleep from './sleep';
import store from '../../store';

const ToolsView = resolve => {
  require.ensure([ '@/views/tools/Tools.vue' ], () => {
    resolve(require('@/views/tools/Tools.vue'));
  }, 'tools');
};

export default {
  path: 'tools',
  components: {
    topNavDesktop: TopNavDesktopView,
    topNavMobile: TopNavMobileView,
    default: BoilerplateView,
  },
  beforeEnter(to, from, next) {
    store.commit('navigation/topHeader', 'app.tools.header_title');
    next();
  },
  children: [
    {
      name: 'tools',
      path: '',
      component: ToolsView,
      async beforeEnter(to, from, next) {
        store.commit('navigation/topDisableBackButton');
        store.commit('navigation/topHeader', 'app.tools.header_title');
        await store.dispatch('tools/getTools');
        next();
      },
    },
    goals,
    thoughts,
    moods,
    journals,
    sleep,
  ],
};
