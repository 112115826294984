


















































import Vue from 'vue';
import { ProfileCommunication } from '@/views/profile/partials/profile-communication.vue';

export default Vue.extend({
  name: 'home-hidden-widgets',
  data() {
    return {
      loading: false,
      opened: false,
    };
  },
  computed: {
    user(): any {
      return this.$store.getters.user;
    },
    internalSettings: {
      get(): ProfileCommunication {
        return this.user.notifications.find((notification: ProfileCommunication) => notification.type === 'INTERNAL');
      },
      set(newValue: ProfileCommunication): void {
        this.user.notifications = this.user.notifications.map((notification: ProfileCommunication) => {
          if (notification.type === 'INTERNAL') {
            return newValue;
          }
          return notification;
        });
      },
    },
    hasHiddenFeatures(): boolean {
      return (this.hasCommentReplies && this.hiddenCommentReplies) || this.hiddenCheckupReminder;
    },
    hiddenCommentReplies(): boolean {
      return !this.internalSettings.commentReplies;
    },
    hiddenCheckupReminder(): boolean {
      return !this.internalSettings.checkUpReminder;
    },
    commentStatuses(): any {
      return this.$store.getters['comments/getCommentsStatuses'];
    },
    hasCommentReplies(): boolean {
      return this.commentStatuses?.some((status: any) => !status.read && !status.homeTileRead);
    },
  },
  methods: {
    onShowFeature(property: string) {
      if (this.loading) return;
      this.loading = true;
      (this.internalSettings as any)[property] = true;
      this.$store.dispatch('editUser', {}).finally(() => {
        this.loading = false;
      });
    },
  },
});
