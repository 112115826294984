<template>
<span class="card-modal" :class="{[`modal-active ${activeModalView}`]: modalActive}">
  <v-card-text class="pa-2" :class="{'completed': sleep.completed}">
    <sleep-track-card-delete
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'delete'"
            :sleep="sleep"
            :closeModal="closeModal"
            @action="onAction">
    </sleep-track-card-delete>
  </v-card-text>
</span>
</template>

<script>
import SleepTrackCardDelete from '@/views/tools/sleep/components/card-modal/sleep-track-card-delete.vue';

export default {
  name: 'sleepTrackCardModal',
  components: {
    SleepTrackCardDelete,
  },
  props: [ 'sleep', 'activeModalView', 'modalActive', 'closeModal' ],
  methods: {
    onAction() {
      this.$emit(this.activeModalView);
    },
  },
};
</script>

<style lang="scss">
.card-modal {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  &.modal-active {
    overflow: auto;
  }
}
</style>
