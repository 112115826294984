
























































































































import Vue from 'vue';
import { IStyleContent } from '@/scripts/store/modules/therapy/types';
import CustomConfirmationModal from '@/views/components/utilities/custom-confirmation-modal.vue';
import WebinarNotificationsPopup from '@/views/components/webinar/webinar-notifications-popup.vue';
import { ProfileCommunication } from '@/views/profile/partials/profile-communication.vue';

export default Vue.extend({
  name: 'element-part-webinar-registration',
  components: { WebinarNotificationsPopup, CustomConfirmationModal },
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      isRegistered: false,
      joinUrl: undefined as string | undefined,
      showConfirmation: false,
      showNotifications: false,
      showErrorMessage: false,
      showRegisterMessage: false,
      showRemovedMessage: false,
      showPushWarning: false,
    };
  },
  computed: {
    isNativePlatform(): boolean {
      return this.$store.getters.isNativePlatform;
    },
    user(): any {
      return this.$store.getters.user;
    },
    emailSettings: {
      get(): ProfileCommunication {
        return this.user.notifications.find((notification: ProfileCommunication) => notification.type === 'EMAIL');
      },
      set(newValue: ProfileCommunication): void {
        this.user.notifications = this.user.notifications.map((notification: ProfileCommunication) => {
          if (notification.type === 'EMAIL') {
            return newValue;
          }
          return notification;
        });
      },
    },
    pushSettings: {
      get(): ProfileCommunication {
        return this.user.notifications.find((notification: ProfileCommunication) => notification.type === 'PUSH');
      },
      set(newValue: ProfileCommunication): void {
        this.user.notifications = this.user.notifications.map((notification: ProfileCommunication) => {
          if (notification.type === 'PUSH') {
            return newValue;
          }
          return notification;
        });
      },
    },
  },
  mounted() {
    this.loading = true;

    this.$store.dispatch('webinar/checkRegistrationWebinar', this.part.id)
      .then(res => {
        this.isRegistered = res.isRegistered;
        this.joinUrl = res.joinUrl;
      }).finally(() => {
        this.loading = false;
      });
  },
  methods: {
    handleRegistrationClick() {
      if (this.loading) return;

      if (this.isRegistered) {
        this.showConfirmation = true;
        return;
      }

      this.loading = true;
      this.showErrorMessage = false;
      this.showNotifications = true;
    },
    onConfirmCancel() {
      this.showNotifications = false;
      this.showConfirmation = false;
    },
    onConfirmDelete() {
      this.loading = true;
      this.showConfirmation = false;
      this.showErrorMessage = false;

      this.$store.dispatch('webinar/unregisterWebinar', this.part.id)
        .then(res => {
          if (res?.status >= 400) throw Error();
          this.isRegistered = false;
          this.joinUrl = undefined;
        }).catch(() => {
          this.showErrorMessage = true;
        }).finally(() => {
          this.loading = false;
        });
    },
    onNotificationsCancel() {
      this.showNotifications = false;
      this.showConfirmation = false;
      this.loading = false;
    },
    onNotificationsConfirm() {
      this.loading = true;

      return this.$store.dispatch('webinar/registerWebinar', this.part.id)
        .then(res => {
          if (res?.status >= 400) throw Error();
          this.isRegistered = res.isRegistered;
          this.joinUrl = res.joinUrl;
        })
        .catch(() => {
          this.showErrorMessage = true;
        })
        .finally(() => {
          this.loading = false;
          this.showNotifications = false;

          if (this.isNativePlatform) {
            this.capacitorCheckPushPermission().catch(() => {
              this.showPushWarning = true;
            });
          }
        });
    },
  },
});
